import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IBaseCompany, IParentCompany } from 'types/income';
import { ContentLanguage, Currency } from 'types/general';
import { DiscountPackage } from 'store/admin-discounts';
import { toCents } from 'utils/general';
import {
  AdminClientsFilters,
  AdminCompaniesFilters,
  ClientFeature,
  DepositManagementMethod,
} from './admin-clients.types';

interface GetClients {
  filters: Partial<AdminClientsFilters>;
}

interface GetClient {
  clientId: string;
}

export interface CreateClient {
  companyName: string;
  companyCountry: string;
  companyCode: string;
  adminEmail: string;
  adminPhone: string;
  adminFirstName: string;
  adminLastName: string;
  internalComment: string;
}

interface UpdateClient {
  clientId: string;
  data: Partial<{
    name: string;
    code: string;
    defaultCurrency: Currency;
    defaultLanguage: Uppercase<ContentLanguage>;
    country: string;
    internalComment: string;
    deactivationDate: string | null;
    features: ClientFeature[];
    discountPlan: DiscountPackage;
    depositManagementMethod: DepositManagementMethod;
    credit: number;
    lowDepositAlertThreshold: number;
  }>;
}

interface DeleteClient {
  clientId: string;
}

interface GetCompanies {
  filters: Partial<AdminCompaniesFilters>;
}

export const loaders = {
  getClients: ({ filters }: GetClients) => {
    return api<TableDataResponse<IParentCompany>>({
      url: Endpoints.melpAdmin.clients.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name || undefined,
          status: filters.status,
          companyIds: filters.companies,
          activityDateFrom: filters.date?.from,
          activityDateTo: filters.date?.to,
        },
      },
    });
  },
  getClient: ({ clientId }: GetClient) => {
    return api<IParentCompany>({
      url: Endpoints.melpAdmin.clients.byId.replace(':clientId', clientId),
    });
  },
  createClient: (values: CreateClient) => {
    return api({
      url: Endpoints.melpAdmin.clients.create,
      data: values,
      method: 'post',
    });
  },
  updateClient: ({ clientId, data }: UpdateClient) => {
    return api({
      url: Endpoints.melpAdmin.clients.byId.replace(':clientId', clientId),
      data: {
        ...data,
        credit:
          typeof data.credit === 'number' ? toCents(data.credit) : undefined,
        lowDepositAlertThreshold:
          typeof data.lowDepositAlertThreshold === 'number'
            ? toCents(data.lowDepositAlertThreshold)
            : undefined,
      },
      method: 'patch',
    });
  },
  deleteClient: ({ clientId }: DeleteClient) => {
    return api({
      url: Endpoints.melpAdmin.clients.byId.replace(':clientId', clientId),
      method: 'delete',
    });
  },
  getCompanies: ({ filters }: GetCompanies) => {
    return api<TableDataResponse<IBaseCompany>>({
      url: Endpoints.melpAdmin.companies.info,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          name: filters.name || undefined,
        },
      },
    });
  },
};
