import { FC } from 'react';
import {
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Tile,
} from 'melp-design/components';
import { Divider, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CompaniesFilter,
  DateRangeFilter,
  DepartmentsFilter,
  EmployeesFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import DownloadExcelButton from 'components/table/DownloadExcelButton/DownloadExcelButton';
import { useSearchParams } from 'utils/navigation';
import {
  recognitionLeaderboardFiltersSchema,
  useExportToXls,
  useRecognitionLeaderboard,
} from 'store/recognition-leaderboard';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import { recognitionTypes } from 'store/recognitions';
import { APP, ROUTES } from 'config';
import { RecipientsTabsSwitcher } from '../atoms';

export const RecognitionsLeaderboardList: FC = () => {
  const { t } = useTranslation();
  const pointsLabel = usePointsLabel();
  const { searchParams: filters, navigate } = useSearchParams(
    recognitionLeaderboardFiltersSchema,
  );

  const { data: leaderboardEmployees, isLoading } =
    useRecognitionLeaderboard(filters);

  const { mutate: exportToXls, isLoading: isExportingToXls } = useExportToXls();

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap={1}>
          <RecipientsTabsSwitcher />

          <Divider />

          <Stack direction="row" alignItems="center" gap="15px">
            {isLoading ? (
              <>
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
              </>
            ) : leaderboardEmployees ? (
              <>
                <Tile
                  title={t('recognitions.received.label', {
                    currency: pointsLabel,
                  })}
                  items={[leaderboardEmployees.meta.receivedPoints]}
                />
                <Tile
                  title={t('recognitions.reward_points.label', {
                    recognitionCurrency: pointsLabel,
                  })}
                  items={[leaderboardEmployees.meta.rewardedPoints]}
                />
                <Tile
                  title={t('recognitions.total.label')}
                  items={[leaderboardEmployees.meta.totalPoints]}
                />
              </>
            ) : null}
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <ListTotal total={leaderboardEmployees?.total} />

            <Stack direction="row" alignItems="center" gap={1}>
              <FiltersInModal
                value={filters}
                initialValues={recognitionLeaderboardFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <>
                    <FilterButton
                      label={t('entity_type.employee')}
                      value={value.employees}
                      initialValue={initialValues.employees}
                      onChange={(v) => setFilter('employees', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <EmployeesFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('employees.company')}
                      value={value.companies}
                      initialValue={initialValues.companies}
                      onChange={(v) => setFilter('companies', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <CompaniesFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('employees.department')}
                      value={value.departments}
                      initialValue={initialValues.departments}
                      onChange={(v) => setFilter('departments', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DepartmentsFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          multiple
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('common.date')}
                      value={value.date}
                      initialValue={initialValues.date}
                      onChange={(v) => setFilter('date', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <DateRangeFilter
                          value={value}
                          onApply={applyFilter}
                          onClear={clearFilter}
                          onCancel={close}
                        />
                      )}
                    </FilterButton>

                    <FilterButton
                      label={t('integrations.type')}
                      value={value.type}
                      initialValue={initialValues.type}
                      onChange={(v) => setFilter('type', v)}
                    >
                      {({ value, applyFilter, clearFilter, close }) => (
                        <SelectFilter
                          value={value}
                          onApplyFilter={applyFilter}
                          onClearFilter={clearFilter}
                          onCancel={close}
                          options={recognitionTypes.map((type) => ({
                            key: type,
                            label: t(`recognitions.type.${type}`),
                          }))}
                          searchable={false}
                          multiple={false}
                        />
                      )}
                    </FilterButton>
                  </>
                )}
              </FiltersInModal>

              <TextFilter
                value={filters.search}
                onApplyFilter={(v) => navigate({ params: { search: v } })}
                placeholder={t('common.search')}
              />

              <Divider orientation="vertical" variant="middle" flexItem />

              <DownloadExcelButton
                listLength={leaderboardEmployees?.total ?? 0}
                exportToExcel={() => exportToXls({ filters })}
                loading={isExportingToXls}
              />
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={leaderboardEmployees?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('entity_type.employee'),
            cell: ({ row: { original: employee } }) => (
              <Link
                label={employee.fullName}
                to={ROUTES.employees.details.replace(':id', employee.id)}
              />
            ),
          },
          {
            id: 'receivedPoints',
            header: t('recognitions.received.label', {
              currency: pointsLabel,
            }),
            cell: ({ row: { original: employee } }) => employee.receivedPoints,
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'rewardedPoints',
            header: t('recognitions.rewarded.label', {
              currency: pointsLabel,
            }),
            cell: ({ row: { original: employee } }) => employee.rewardedPoints,
            meta: { sort: true, align: 'right' },
          },
          {
            id: 'totalPoints',
            header: t('recognitions.total.label'),
            cell: ({ row: { original: employee } }) => employee.totalPoints,
            meta: { sort: true, align: 'right' },
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          leaderboardEmployees && {
            count: leaderboardEmployees.total,
            page: leaderboardEmployees.page,
            pageSize: leaderboardEmployees.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
