import { APP } from 'config';
import { TableDataResponse } from 'types/Table';

export const converters = {
  getTableData: (data: Omit<TableDataResponse<any>, 'data'>) => {
    const params = {
      total: data.count ?? 0,
      page: data.page ?? 1,
      pageSize: data.pageSize ?? APP.pagination.defaults.pageSize[0],
    };
    return {
      total: params.total,
      page: params.page,
      pageSize: params.pageSize,
      pages: Math.ceil(params.total / params.pageSize),
    };
  },
};
