import { z } from 'zod';
import {
  dateRangeSchema,
  paginationFiltersSchema,
  singleDateSchema,
  sortFiltersSchema,
} from 'utils/schemas';
import { expenseTypes } from 'store/expenses';
import { clientCompanyDepositHistoryTypes } from './admin-client-deposits.types';

export const adminClientDepositsFiltersSchema = z
  .object({
    date: singleDateSchema,
  })
  .merge(sortFiltersSchema);

export const adminCompanyExpensesFiltersSchema = z
  .object({
    type: z.array(z.enum(expenseTypes)).catch([]),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema);

export const adminDepositEventsFiltersSchema = z
  .object({
    type: z.array(z.enum(expenseTypes)).catch([]),
    client: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);

export const adminDepositHistoryFiltersSchema = z
  .object({
    type: z.array(z.enum(clientCompanyDepositHistoryTypes)).catch([]),
    date: singleDateSchema,
    owner: z.array(z.string()).catch([]),
  })
  .merge(paginationFiltersSchema);

export const adminDepositBalancesPerClientFiltersSchema = z.object({
  date: singleDateSchema,
  clients: z.array(z.string()).catch([]),
});

export const adminDepositBalancesPerCompanyFiltersSchema = z.object({
  date: singleDateSchema,
  companies: z.array(z.string()).catch([]),
});
