import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-admins.loaders';
import { converters } from './admin-admins.converters';
import { AdminAdminsFilters } from './admin-admins.types';

export const adminAdminsKeys = queryKeyFactory('admin-admins');

export const useAdminAdmins = (filters: Partial<AdminAdminsFilters> = {}) => {
  return useQuery({
    queryKey: adminAdminsKeys.list(filters),
    queryFn: () => loaders.getAdmins({ filters }),
    select: converters.getAdmins,
    keepPreviousData: true,
  });
};

export const useAdminAdmin = (adminId: string) => {
  return useQuery({
    queryKey: adminAdminsKeys.detail(adminId),
    queryFn: () => loaders.getAdmin({ adminId }),
    select: (data) => converters.getAdmin(data),
    enabled: !!adminId,
  });
};
