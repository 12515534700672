import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { auth } from '../auth/index';

interface BEErrorResponse {
  message: string | string[];
  subCode: number;
  errorId: string;
}

const isBEErrorResponse = (obj: any): obj is BEErrorResponse => {
  return 'errorId' in obj;
};

const ERROR_MESSAGE_FALLBACK = 'Something went wrong!';
const ERROR_ID_LABEL_FALLBACK = 'Error ID';

const showErrorMessage = async (error: any) => {
  const defaultErrorMessage = i18n.t(
    'errors.genericErrorMessage',
    ERROR_MESSAGE_FALLBACK,
  );

  const getMessageWithErrorId = (message: string, id: string) => {
    // handle empty message
    const finalMessage = message || defaultErrorMessage;
    const errorIdLabel = i18n.t('errors.errorId', ERROR_ID_LABEL_FALLBACK);
    return `${finalMessage}\n${errorIdLabel}: ${id}`;
  };

  const showDefaultMessage = () => {
    toast.error(defaultErrorMessage);
  };

  if (!error) {
    showDefaultMessage();
    return;
  }

  if (error.response) {
    const responseStatus: number = error.response.status;

    if (responseStatus === 401) {
      // Sentry collects steps that have lead to this error.
      Sentry.captureException('Logout due to 401');
      // Logout a user from the app, if unauthorized request error comes from
      // the backend. This action is required to prevent undefined behavior.
      // It should be a very rare case when this code is invoked because AWS
      // Amplify Auth service updates tokens itself. It is expected that a user
      // will be logged out this way only when refresh token has expired.
      await auth.logout();
      return;
    }

    // Not having certain permissions is ok. UI should not display an error
    // but instead should hide certain parts which are not accessible.
    if (responseStatus === 403) {
      return;
    }

    const responseData = error.response.data;
    if (responseData && isBEErrorResponse(responseData)) {
      const { message, subCode, errorId } = responseData;

      const isAssignmentFilterNotFoundError =
        responseStatus === 404 && message === 'AssignmentFilter not found';
      const isCompanyGroupsNotFoundError = subCode === 14001;
      // Ignore these messages as they represent  a normal system state
      // (e.g., an entity does not exist because it was not created yet)
      if (isAssignmentFilterNotFoundError || isCompanyGroupsNotFoundError) {
        return;
      }

      if (Array.isArray(message)) {
        message.forEach((messageItem) =>
          toast.error(getMessageWithErrorId(messageItem, errorId)),
        );
        return;
      }

      toast.error(getMessageWithErrorId(message, errorId));
      return;
    }
  }

  if (error.message) {
    toast.error(error.message);
    return;
  }

  showDefaultMessage();
};

export const handleResponseError = async (error: any) => {
  await showErrorMessage(error);
  return Promise.reject(error);
};
