/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} needles the array providing needles to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from needles.
 */
export const containsAtLeastOne = <T extends string>(
  haystack: T[],
  needles: T[],
) => {
  return needles.some((v) => haystack.includes(v));
};

/**
 * @description determine if an array contains all items from another array.
 * @param {array} haystack the array to search.
 * @param {array} needles the array providing needles to check for in the haystack.
 * @return {boolean} true|false if haystack contains all item from needles.
 */
export const containsAll = <T extends string>(haystack: T[], needles: T[]) => {
  return needles.every((v) => haystack.includes(v));
};
