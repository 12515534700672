import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from 'melp-design/components';
import { useModalContext } from 'store/modal';
import {
  CreateDepositModal,
  SetCreditLimitModal,
  SetLowDepositAlertModal,
} from 'containers/admin';

interface Props {
  clientId: string;
  companyId: string;
  credit: number;
}

export const AdminClientDepositsActions: FC<Props> = ({
  clientId,
  companyId,
  credit,
}) => {
  const { t } = useTranslation();
  const { openModal } = useModalContext();

  return (
    <Menu
      label={t('common.actions')}
      items={[
        {
          label: t('clients.actions.add_deposit'),
          onClick: () => {
            openModal({
              component: CreateDepositModal,
              props: { type: 'add', clientId, companyId },
            });
          },
        },
        {
          label: t('clients.actions.deduct_deposit'),
          onClick: () => {
            openModal({
              component: CreateDepositModal,
              props: { type: 'deduct', clientId, companyId },
            });
          },
        },
        {
          label: t('clients.actions.set_low_deposit_alert'),
          onClick: () => {
            openModal({
              component: SetLowDepositAlertModal,
              props: {
                clientId,
                companyId,
              },
            });
          },
        },
        {
          label: t('clients.actions.set_credit_limit'),
          onClick: () => {
            openModal({
              component: SetCreditLimitModal,
              props: { clientId, companyId, initialAmount: credit },
            });
          },
        },
      ]}
    />
  );
};
