import { FC } from 'react';
import {
  Button,
  Link,
  ListPageHeader,
  ListTotal,
  Table,
  Typography,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { APP, ROUTES } from 'config';
import { FilterButton, FiltersInModal, SelectFilter } from 'components/filters';
import TextFilter from 'components/filters/TextFilter';
import { useSearchParams } from 'utils/navigation';
import {
  adminAdminsFiltersSchema,
  adminStatuses,
  COLOR_BY_STATUS,
  useAdminAdmins,
} from 'store/admin-admins';

export const AdminAdminsList: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { searchParams: filters, navigate } = useSearchParams(
    adminAdminsFiltersSchema,
  );

  const { data: admins, isLoading } = useAdminAdmins(filters);

  return (
    <>
      <ListPageHeader
        title={t('sidebar.administrators')}
        sideContent={
          <Button
            label={t('administrators.add_administrator')}
            onClick={() => history.push(ROUTES.admin.admins.create)}
          />
        }
        toolbarContent={
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={admins?.total} />

            <Stack direction="row" alignItems="center" gap="10px">
              <FiltersInModal
                value={filters}
                initialValues={adminAdminsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
              >
                {({ value, initialValues, setFilter }) => (
                  <FilterButton
                    label={t('common.status')}
                    value={value.status}
                    initialValue={initialValues.status}
                    onChange={(v) => setFilter('status', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        name="status"
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple={true}
                        searchable={false}
                        options={adminStatuses.map((status) => ({
                          key: status,
                          label: t(`status.${status}`),
                        }))}
                      />
                    )}
                  </FilterButton>
                )}
              </FiltersInModal>

              <TextFilter
                value={filters.name}
                onApplyFilter={(v) => navigate({ params: { name: v } })}
                placeholder={t('common.search')}
              />
            </Stack>
          </Stack>
        }
      />

      <Table
        data={admins?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('common.name'),
            cell: ({ row: { original: admin } }) => (
              <Link
                label={admin.fullName}
                to={ROUTES.admin.admins.details.replace(':id', admin.id)}
              />
            ),
          },
          {
            id: 'email',
            header: t('common.email'),
            cell: ({ row: { original: admin } }) => (
              <Link
                label={admin.email}
                to={ROUTES.admin.admins.details.replace(':id', admin.id)}
              />
            ),
          },
          {
            id: 'phone',
            header: t('client.admin_phone'),
            cell: ({ row: { original: admin } }) => admin.phone,
          },
          {
            id: 'status',
            header: t('common.status'),
            cell: ({ row: { original: admin } }) => (
              <Typography color={COLOR_BY_STATUS[admin.status]}>
                {t(`status.${admin.status}`)}
              </Typography>
            ),
          },
        ]}
        sort={{
          value: filters.sort,
          onChange: (v) => navigate({ params: { sort: v } }),
        }}
        pagination={
          admins && {
            count: admins.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </>
  );
};
