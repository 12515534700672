import { mutate } from 'swr';
import { Endpoints } from '../api/constants';
import { PaginationParams } from '../types/Common';
import {
  CreateRecognitionAllowanceRuleRequest,
  RecognitionAchievement,
  RecognitionAchievementRequest,
  RecognitionAllowanceEmployeeOverviewResponse,
  RecognitionAllowanceOverviewResponse,
  RecognitionAllowanceRule,
  RecognitionAllowanceRulesListResponse,
  RecognitionGiversResponse,
  RecognitionRecipientSendersDetailsResponse,
  RecognitionSenderRecipientsDetailsResponse,
  UpdateRecognitionAllowanceRuleRequest,
} from '../types/Recognition';
import { useGet, useCustomSwrMutation, useXLSExport } from '../utils/Api';
import { convertToQueryParams } from '../utils/Filters';

export const useRecognitionAchievementsMutations = () => {
  const create = useCustomSwrMutation<
    RecognitionAchievementRequest,
    RecognitionAchievement
  >(Endpoints.clientAdmin.recognition.achievements.root, 'post');

  return { create };
};

export const useRecognitionAchievement = (id: string) => {
  return useGet<RecognitionAchievement>(
    Endpoints.clientAdmin.recognition.achievements.byId(id).root,
  );
};

export const useRecognitionAchievementMutations = (id: string) => {
  const achievementsUrls = Endpoints.clientAdmin.recognition.achievements;
  const url = achievementsUrls.byId(id).root;

  const update = useCustomSwrMutation<
    RecognitionAchievementRequest,
    RecognitionAchievement
  >(url, 'patch', { onSuccess: (response) => mutate(url, response) });

  const remove = useCustomSwrMutation<void, void>(url, 'delete', {
    onSuccess: async () => await mutate(achievementsUrls.root),
  });

  return {
    update,
    remove,
  };
};

export const useRecognitionAllowanceRules = () => {
  return useGet<RecognitionAllowanceRulesListResponse>(
    Endpoints.clientAdmin.recognition.allowanceRules.root,
  );
};

export const useRecognitionAllowanceRulesMutations = () => {
  const url = Endpoints.clientAdmin.recognition.allowanceRules.root;
  const create = useCustomSwrMutation<
    CreateRecognitionAllowanceRuleRequest,
    RecognitionAllowanceRule
  >(url, 'post', { onSuccess: async () => await mutate(url) });

  return { create };
};

export const useRecognitionAllowanceRuleMutations = (id: string) => {
  const allowanceRulesUrls = Endpoints.clientAdmin.recognition.allowanceRules;
  const url = allowanceRulesUrls.byId(id).root;

  const update = useCustomSwrMutation<
    UpdateRecognitionAllowanceRuleRequest,
    RecognitionAllowanceRule
  >(url, 'patch', {
    onSuccess: async () => await mutate(allowanceRulesUrls.root),
  });

  const remove = useCustomSwrMutation<void, void>(url, 'delete', {
    onSuccess: async () => await mutate(allowanceRulesUrls.root),
  });

  return {
    update,
    remove,
  };
};

export const useAllowanceOverview = (params: PaginationParams) => {
  const query = convertToQueryParams(params);

  const response = useGet<RecognitionAllowanceOverviewResponse>(
    Endpoints.clientAdmin.recognition.allowance.overview.root + query,
  );

  const xlsExport = useXLSExport(
    Endpoints.clientAdmin.recognition.allowance.overview.exportToExcel + query,
  );

  return { ...response, xlsExport };
};

interface RecognitionGiversParams extends PaginationParams {
  employeeName?: string;
  dateFrom?: string;
  dateTo?: string;
  sort?: string;
}

export const useRecognitionGivers = (params: RecognitionGiversParams) => {
  const query = convertToQueryParams(params);

  const response = useGet<RecognitionGiversResponse>(
    Endpoints.clientAdmin.recognition.givers.root + query,
  );

  const xlsExport = useXLSExport(
    Endpoints.clientAdmin.recognition.givers.exportToExcel + query,
  );

  return { ...response, xlsExport };
};

export const useRecognitionAllowanceEmployeeOverview = (
  employeeId: string,
  params: PaginationParams,
) => {
  const query = convertToQueryParams(params);
  return useGet<RecognitionAllowanceEmployeeOverviewResponse>(
    Endpoints.clientAdmin.recognition.allowance.overview.employee.byId(
      employeeId,
    ).root + query,
  );
};

interface RecognitionRecipientSendersDetailsParams extends PaginationParams {
  dateFrom?: string;
  dateTo?: string;
}

export const useRecognitionRecipientSendersDetails = (
  employeeId: string,
  params: RecognitionRecipientSendersDetailsParams,
) => {
  const query = convertToQueryParams(params);
  return useGet<RecognitionRecipientSendersDetailsResponse>(
    Endpoints.clientAdmin.recognition.recipients.employee.byId(employeeId)
      .root + query,
  );
};

interface RecognitionSenderRecipientsDetailsParams extends PaginationParams {
  dateFrom?: string;
  dateTo?: string;
}

export const useRecognitionSenderRecipientsDetails = (
  employeeId: string,
  params: RecognitionSenderRecipientsDetailsParams,
) => {
  const query = convertToQueryParams(params);
  return useGet<RecognitionSenderRecipientsDetailsResponse>(
    Endpoints.clientAdmin.recognition.givers.employee.byId(employeeId).root +
      query,
  );
};
