import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import { TableDataResponse } from 'types/Table';
import { IAdminAdmin } from 'types/income';
import { getSorting } from 'utils/general';
import {
  AdminAdminsFilters,
  AdminPermission,
  AdminStatus,
} from './admin-admins.types';

interface GetAdmins {
  filters: Partial<AdminAdminsFilters>;
}

interface GetAdmin {
  adminId: string;
}

interface CreateAdmin {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  status: AdminStatus;
  permissions: AdminPermission[];
}

interface UpdateAdmin {
  adminId: string;
  data: Partial<CreateAdmin>;
}

interface DeleteAdmin {
  adminId: string;
}

export const loaders = {
  getAdmins: ({ filters }: GetAdmins) => {
    return api<TableDataResponse<IAdminAdmin>>({
      url: Endpoints.melpAdmin.admins.root,
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        ...getSorting(filters.sort),
        filter: {
          name: filters.name || undefined,
          phone: filters.phone || undefined,
          email: filters.email || undefined,
          status: filters.status,
        },
      },
    });
  },
  getAdmin: ({ adminId }: GetAdmin) => {
    return api<IAdminAdmin>({
      url: Endpoints.melpAdmin.admins.byId.replace(':adminId', adminId),
    });
  },
  createAdmin: (values: CreateAdmin) => {
    return api({
      url: Endpoints.melpAdmin.admins.create,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        status: values.status,
        melpPermissions: values.permissions,
      },
      method: 'post',
    });
  },
  updateAdmin: ({ adminId, data }: UpdateAdmin) => {
    return api({
      url: Endpoints.melpAdmin.admins.byId.replace(':adminId', adminId),
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        status: data.status,
        melpPermissions: data.permissions,
      },
      method: 'patch',
    });
  },
  deleteAdmin: ({ adminId }: DeleteAdmin) => {
    return api({
      url: Endpoints.melpAdmin.admins.byId.replace(':adminId', adminId),
      method: 'delete',
    });
  },
};
