import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { AutocompleteInput, Modal, NumberInput } from 'melp-design/components';
import {
  useAdminClientDeposits,
  useAdminClientUpdateDepositSettings,
} from 'store/admin-client-deposits';
import { ModalProps } from 'store/modal';
import { Stack } from '@mui/material';
import { useAdminClient, useAdminUpdateClient } from 'store/admin-clients';
import { usePredefinedToasts } from 'utils/Toast';
import { fromCents } from 'utils/general';

interface Values {
  company: string;
  amount: number;
}

interface Props extends ModalProps {
  clientId: string;
  companyId: string;
  initialAmount: number;
}

export const SetCreditLimitModal: FC<Props> = ({
  clientId,
  companyId,
  initialAmount,
  closeModal,
}) => {
  const { t } = useTranslation();
  const predefinedToasts = usePredefinedToasts();

  const { control, handleSubmit } = useForm<Values>({
    defaultValues: {
      company: companyId,
      amount: fromCents(initialAmount),
    },
  });

  const { data: companies } = useAdminClientDeposits(clientId);
  const { data: client } = useAdminClient(clientId);

  const { mutate: updateCompanySettings, isLoading: isUpdatingCompany } =
    useAdminClientUpdateDepositSettings();
  const { mutate: updateClientSettings, isLoading: isUpdatingClient } =
    useAdminUpdateClient();

  const onSubmit: SubmitHandler<Values> = ({ company, amount }) => {
    if (companyId) {
      updateCompanySettings(
        {
          companyId: company,
          settings: { credit: amount },
        },
        {
          onSuccess: () => {
            predefinedToasts.success.updated();
            closeModal({ action: 'CLOSE' });
          },
        },
      );
    } else {
      updateClientSettings(
        {
          clientId,
          data: { credit: amount },
        },
        {
          onSuccess: () => {
            predefinedToasts.success.updated();
            closeModal({ action: 'CLOSE' });
          },
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal.Content
        title={t('clients.actions.set_credit_limit')}
        actions={[
          {
            variant: 'neutral-outline',
            onClick: () => closeModal({ action: 'CLOSE' }),
            label: t('common.cancel'),
          },
          {
            variant: 'primary',
            label: t('common.confirm'),
            type: 'submit',
            disabled: isUpdatingCompany || isUpdatingClient || !client,
          },
        ]}
      >
        <Stack gap={2}>
          {companyId ? (
            <Controller
              name="company"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <AutocompleteInput
                  label={t('employees.company')}
                  name={name}
                  value={value}
                  onChange={onChange}
                  options={
                    companies?.items.map((company) => ({
                      label: company.name,
                      value: company.id,
                    })) ?? []
                  }
                  disabled
                  required
                  ref={ref}
                />
              )}
            />
          ) : null}

          <Controller
            name="amount"
            control={control}
            render={({ field: { name, value, onChange, ref } }) => (
              <NumberInput
                label={t('employees.amount')}
                name={name}
                value={value}
                onChange={onChange}
                required
                key={value}
                ref={ref}
                InputProps={{
                  endAdornment: client?.defaultCurrency,
                }}
              />
            )}
          />
        </Stack>
      </Modal.Content>
    </form>
  );
};
