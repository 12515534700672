import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IAdminCompanyExpense,
  IAdminClientDeposit,
  IAdminDepositEvent,
  IAdminDepositHistory,
  IAdminDepositsTotals,
  IAdminDepositBalancesPerClient,
  IAdminDepositBalancesPerCompany,
  IDepositPendingActions,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { Currency } from 'types/general';
import { exportXlsxConfig, toCents } from 'utils/general';
import { ExpenseType, revertExpenseType } from 'store/expenses';
import {
  AdminCompanyExpensesFilters,
  AdminClientDepositsFilters,
  AdminDepositEventsFilters,
  AdminDepositHistoryFilters,
  AdminDepositBalancesPerClientFilters,
  AdminDepositBalancesPerCompanyFilters,
} from './admin-client-deposits.types';

const getDepositsFilters = (filters: Partial<AdminClientDepositsFilters>) => ({
  filter: {
    limitDate: filters.date,
  },
});

const getExpensesFilters = (filters: Partial<AdminCompanyExpensesFilters>) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    type: filters.type?.map(revertExpenseType),
    dateFrom: filters.date?.from,
    dateTo: filters.date?.to,
  },
});

const getDepositEventsFilters = (
  filters: Partial<AdminDepositEventsFilters>,
) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    parentCompanyIds: filters.client,
    depositTypes: filters.type?.map(revertExpenseType),
  },
});

const getDepositHistoryFilters = (
  filters: Partial<AdminDepositHistoryFilters>,
) => ({
  page: filters.page,
  pageSize: filters.pageSize,
  filter: {
    type: filters.type,
    doneBy: filters.owner,
    limitDate: filters.date,
  },
});

interface GetDeposits {
  clientId: string;
  filters: Partial<AdminClientDepositsFilters>;
}

interface CreateCompanyDeposit {
  companyId: string;
  data: {
    date: string;
    amount: number;
    currency: Currency;
    comment: string;
    invoice: string;
  };
}

interface CreateClientDeposit {
  clientId: string;
  data: {
    date: string;
    amount: number;
    currency: Currency;
    comment: string;
    invoice: string;
  };
}

interface UpdateDeposit {
  depositId: string;
  data: Partial<CreateCompanyDeposit['data']>;
}

interface GetCompanyExpenses {
  companyId: string;
  filters: Partial<AdminCompanyExpensesFilters>;
}

interface UpdateSettings {
  companyId: string;
  settings: Partial<{
    credit: number;
    lowDepositAlert: number;
  }>;
}

interface ExportXlsDeposits {
  companyId: string;
  filters: Partial<AdminClientDepositsFilters>;
}

interface ExportXlsExpenses {
  clientId: string;
  companyId: string;
  filters: Partial<AdminCompanyExpensesFilters>;
}

interface GetDepositEvents {
  filters: Partial<AdminDepositEventsFilters>;
}

interface CreateDepositEvent {
  clientId: string;
  expenseType: ExpenseType;
  from: string;
  to?: string;
}

interface EditDepositEvent {
  eventId: string;
  from: string;
  to?: string;
}

interface DeleteDepositEvent {
  eventId: string;
}

interface EnableDeposits {
  clientId: string;
  type: ExpenseType;
  enabled: boolean;
}

interface GetDepositHistory {
  clientId: string;
  filters: Partial<AdminDepositHistoryFilters>;
}

interface GetDepositBalancesPerClient {
  filters: Partial<AdminDepositBalancesPerClientFilters>;
}

interface GetDepositBalancesPerCompany {
  filters: Partial<AdminDepositBalancesPerCompanyFilters>;
}

export const loaders = {
  getDeposits: ({ clientId, filters }: GetDeposits) => {
    return api<IAdminClientDeposit>({
      url: Endpoints.melpAdmin.clients.deposits.clients.root.replace(
        ':clientId',
        clientId,
      ),
      params: getDepositsFilters(filters),
    });
  },
  createCompanyDeposit: ({ companyId, data }: CreateCompanyDeposit) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.companies.root.replace(
        ':companyId',
        companyId,
      ),
      data: {
        amountInCents: toCents(data.amount),
        currency: data.currency,
        invoice: data.invoice,
        comment: data.comment,
        date: data.date,
      },
      method: 'post',
    });
  },
  createClientDeposit: ({ clientId, data }: CreateClientDeposit) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.clients.root.replace(
        ':clientId',
        clientId,
      ),
      data: {
        amountInCents: toCents(data.amount),
        currency: data.currency,
        invoice: data.invoice,
        comment: data.comment,
        date: data.date,
      },
      method: 'post',
    });
  },
  updateDeposit: ({ depositId, data }: UpdateDeposit) => {
    return api<IAdminClientDeposit>({
      url: Endpoints.melpAdmin.clients.deposits.byId.replace(
        ':depositId',
        depositId,
      ),
      data: {
        comment: data.comment,
        invoice: data.invoice,
        amountInCents: data.amount,
        currency: data.currency,
        date: data.date,
      },
      method: 'patch',
    });
  },
  getCompanyExpenses: ({ companyId, filters }: GetCompanyExpenses) => {
    return api<TableDataResponse<IAdminCompanyExpense>>({
      url: Endpoints.melpAdmin.clients.deposits.companies.expenses.replace(
        ':companyId',
        companyId,
      ),
      params: getExpensesFilters(filters),
    });
  },
  updateSettings: ({ companyId, settings }: UpdateSettings) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.companies.settings.replace(
        ':companyId',
        companyId,
      ),
      data: {
        credit:
          typeof settings.credit === 'number'
            ? toCents(settings.credit)
            : undefined,
        lowDepositAlertThreshold:
          typeof settings.lowDepositAlert === 'number'
            ? toCents(settings.lowDepositAlert)
            : undefined,
      },
      method: 'patch',
    });
  },
  exportXlsDeposits: ({ companyId, filters }: ExportXlsDeposits) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.companies.export.xls.replace(
        ':companyId',
        companyId,
      ),
      params: getDepositsFilters(filters),
      ...exportXlsxConfig,
    });
  },
  exportXlsExpenses: ({ clientId, companyId, filters }: ExportXlsExpenses) => {
    return api({
      url: Endpoints.melpAdmin.clients.balance.export.xls
        .replace(':clientId', clientId)
        .replace(':companyId', companyId),
      params: getExpensesFilters(filters),
      ...exportXlsxConfig,
    });
  },
  getDepositEvents: ({ filters }: GetDepositEvents) => {
    return api<TableDataResponse<IAdminDepositEvent>>({
      url: Endpoints.melpAdmin.clients.deposits.events.root,
      params: getDepositEventsFilters(filters),
    });
  },
  createDepositEvent: ({
    clientId,
    expenseType,
    from,
    to,
  }: CreateDepositEvent) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.events.root,
      data: {
        parentCompanyId: clientId,
        type: revertExpenseType(expenseType),
        from,
        to,
      },
      method: 'post',
    });
  },
  editDepositEvent: ({ eventId, from, to }: EditDepositEvent) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.events.byId.replace(
        ':eventId',
        eventId,
      ),
      data: { from, to },
      method: 'patch',
    });
  },
  deleteDepositEvent: ({ eventId }: DeleteDepositEvent) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.events.byId.replace(
        ':eventId',
        eventId,
      ),
      method: 'delete',
    });
  },
  enableDeposits: ({ clientId, type, enabled }: EnableDeposits) => {
    return api({
      url: Endpoints.melpAdmin.clients.deposits.clients.enableDeposits.replace(
        ':clientId',
        clientId,
      ),
      data: {
        type: revertExpenseType(type),
        status: enabled ? 'active' : 'inactive',
      },
      method: 'patch',
    });
  },
  getDepositHistory: ({ clientId, filters }: GetDepositHistory) => {
    return api<TableDataResponse<IAdminDepositHistory> & IAdminDepositsTotals>({
      url: Endpoints.melpAdmin.clients.deposits.clients.history.replace(
        ':clientId',
        clientId,
      ),
      params: getDepositHistoryFilters(filters),
    });
  },
  getDepositBalancesPerClient: ({ filters }: GetDepositBalancesPerClient) => {
    return api<{ data: IAdminDepositBalancesPerClient[] }>({
      url: Endpoints.melpAdmin.clients.deposits.clients.balances,
      params: {
        filter: {
          parentCompanyIds: filters.clients,
          limitDate: filters.date,
        },
      },
    });
  },
  getDepositBalancesPerCompany: ({ filters }: GetDepositBalancesPerCompany) => {
    return api<{ data: IAdminDepositBalancesPerCompany[] }>({
      url: Endpoints.melpAdmin.clients.deposits.companies.balances,
      params: {
        filter: {
          companyIds: filters.companies,
          limitDate: filters.date,
        },
      },
    });
  },
  getPendingActions: () => {
    return api<IDepositPendingActions>({
      url: Endpoints.melpAdmin.clients.deposits.pendingActions,
    });
  },
};
