import { ROUTES } from 'config';
import { Tabs } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAdminDepositPendingActions } from 'store/admin-client-deposits';

export const TabsSwitcher: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  const { data: pendingActions } = useAdminDepositPendingActions();

  return (
    <Tabs
      value={pathname}
      onChange={(v) => history.push(v)}
      items={[
        {
          value: ROUTES.admin.deposits.balances.clients.list,
          label: t('deposits.method.clientAccount'),
          badge: pendingActions?.clients,
        },
        {
          value: ROUTES.admin.deposits.balances.companies.list,
          label: t('deposits.method.company'),
          badge: pendingActions?.companies,
        },
      ]}
    />
  );
};
