import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TableOld,
} from 'melp-design/components';
import { useTranslation } from 'react-i18next';
import { Currency } from 'types/general';
import { useParentCompany } from 'state/ParentCompany';
import { useRecognitionRecipientSendersDetails } from 'state/Recognition';
import { DateRange } from 'types/Common';
import { currencyFormatter } from 'utils/Currency';
import { useDialog } from 'utils/Dialog';
import { usePagination } from 'utils/Pagination';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';

interface Props {
  employeeId: string;
  recognitionDateRange?: DateRange;
}

const Content = ({ recognitionDateRange, ...props }: Props) => {
  const { t } = useTranslation();

  const { page, pageSize, setPage, setPageSize } = usePagination();
  const recipientSenders = useRecognitionRecipientSendersDetails(
    props.employeeId,
    {
      page,
      pageSize,
      dateFrom: recognitionDateRange?.from,
      dateTo: recognitionDateRange?.to,
    },
  );

  const recognitionCurrency = usePointsLabel();

  const { parentCompany } = useParentCompany();
  const accountCurrency = parentCompany?.defaultCurrency as Currency;

  return (
    <>
      <DialogTitle>{t('recognition.recipientsListSendersDetails')}</DialogTitle>
      <DialogContent>
        <TableOld
          data={recipientSenders.data?.data}
          loading={recipientSenders.loading}
          rowKey="id"
          columns={[
            {
              key: 'name',
              title: t('entity_type.employee'),
              render: (_, { firstName, lastName }) =>
                [firstName, lastName].join(' '),
            },
            {
              key: 'points',
              title: t('recognition.recipientSendersDetailsListTotalGiven', {
                recognitionCurrency,
              }),
              align: 'right',
            },
            {
              key: 'currencyValue',
              title: t('recognition.recipientSendersDetailsListTotalAmount', {
                accountCurrency,
              }),
              render: (value: number) =>
                currencyFormatter.formatFractionalUnitAmount(
                  value,
                  accountCurrency,
                ),
              align: 'right',
            },
            {
              key: 'count',
              title: t('recognition.recipientSendersDetailsListCount'),
              align: 'right',
            },
          ]}
          pagination={
            recipientSenders.data
              ? {
                  count: recipientSenders.data.count,
                  page: recipientSenders.data.page,
                  pageSize: recipientSenders.data.pageSize,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setPageSize(pageSize);
                  },
                }
              : undefined
          }
        />
      </DialogContent>
    </>
  );
};

const RecipientSendersDetails = (props: Props) => {
  const { t } = useTranslation();

  const detailsDialog = useDialog();

  return (
    <>
      <Button
        label={t('recognition.recipientsListSendersDetailsLink')}
        size="sm"
        variant="neutral-outline"
        onClick={detailsDialog.openDialog}
      />

      <Dialog
        open={detailsDialog.open}
        onClose={detailsDialog.closeDialog}
        width={800}
      >
        <Content {...props} />
      </Dialog>
    </>
  );
};

export default RecipientSendersDetails;
