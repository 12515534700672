import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'config';
import { Loader } from 'melp-design/components';
import { pages } from 'config';
import { useMe } from 'state/Administrators';
import { ProtectedRoute } from './ProtectedRoute';

export default function Routes() {
  const { me } = useMe();

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {me?.role === 'melpAdmin' && (
          <Route
            path={ROUTES.root.index}
            exact
            render={() => <Redirect to={ROUTES.admin.clients.list} />}
          />
        )}
        {pages.map((route) => (
          <Route key={route.path} path={route.path} exact>
            {route.public ? (
              <route.component />
            ) : (
              <ProtectedRoute
                path={route.path}
                roles={route.roles}
                permissionLevel={route.permissionLevel}
                module={route.module}
                adminModules={route.adminModules}
              >
                <route.component />
              </ProtectedRoute>
            )}
          </Route>
        ))}
        <Route path="*" render={() => <Redirect to={ROUTES.root.notFound} />} />
      </Switch>
    </Suspense>
  );
}
