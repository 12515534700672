import { Stack } from '@mui/material';
import { ROUTES } from 'config';
import {
  Button,
  DataLossPrompt,
  Footer,
  Loader,
  NothingFoundAlert,
  Panel,
  SelectInput,
  TextField,
} from 'melp-design/components';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePredefinedToasts } from 'utils/Toast';
import { useRouteParams } from 'utils/useIdParam';
import {
  AdminPermission,
  adminPermissions,
  AdminStatus,
  adminStatuses,
  useAdminAdmin,
  useAdminCreateAdmin,
  useAdminUpdateAdmin,
} from 'store/admin-admins';

interface Values {
  status: AdminStatus;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  permissions: AdminPermission[];
}

export const AdminAdminDetails: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: adminId } = useRouteParams(ROUTES.admin.admins.details);
  const predefinedToasts = usePredefinedToasts();

  const { data: admin, isLoading } = useAdminAdmin(adminId);

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = useForm<Values>({
    defaultValues: {
      status: 'active',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      permissions: [],
    },
  });

  useEffect(() => {
    if (admin) {
      reset({
        status: admin.status,
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        phone: admin.phone,
        permissions: admin.permissions,
      });
    }
  }, [admin, reset]);

  const { mutate: createAdmin, isLoading: isCreating } = useAdminCreateAdmin();
  const { mutate: updateAdmin, isLoading: isUpdating } = useAdminUpdateAdmin();

  const onSubmit: SubmitHandler<Values> = (values) => {
    const valuesToData = {
      status: values.status,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      permissions: values.permissions,
    } as const;

    if (admin) {
      updateAdmin(
        { adminId, data: valuesToData },
        {
          onSuccess: () => {
            predefinedToasts.success.updated();
          },
        },
      );
    } else {
      createAdmin(valuesToData, {
        onSuccess: ({ data: { id } }) => {
          predefinedToasts.success.added();
          history.push(ROUTES.admin.admins.details.replace(':id', id));
        },
      });
    }
  };

  const isDirty = !!Object.keys(dirtyFields).length;

  if (adminId && isLoading) return <Loader />;
  if (adminId && !admin) return <NothingFoundAlert />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Panel title={t('common.settings')}>
            <Stack spacing={2}>
              <Controller
                name="status"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      label={t('common.status')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={adminStatuses.map((status) => ({
                        label: t(`status.${status}`),
                        value: status,
                      }))}
                      required
                      ref={ref}
                    />
                  );
                }}
              />

              <Controller
                name="firstName"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('form.first_name')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="lastName"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('form.last_name')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="email"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('common.email')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />

              <Controller
                name="phone"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => (
                  <TextField
                    label={t('marketplaceOrders.contactPhone')}
                    name={name}
                    value={value}
                    onChange={onChange}
                    required
                    ref={ref}
                  />
                )}
              />
            </Stack>
          </Panel>

          <Panel title={t('sidebar.administratorsPermissions')}>
            <Stack spacing={2}>
              <Controller
                name="permissions"
                control={control}
                render={({ field: { name, value, onChange, ref } }) => {
                  return (
                    <SelectInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={adminPermissions.map((permission) => ({
                        label: t(`permissions.${permission}`),
                        value: permission,
                      }))}
                      multiple
                      ref={ref}
                    />
                  );
                }}
              />
            </Stack>
          </Panel>
        </Stack>

        <Footer visible={isDirty}>
          <Stack direction="row" justifyContent="end" gap="10px">
            <Button
              label={t('common.cancel')}
              variant="neutral-outline"
              onClick={() => {
                reset(undefined, { keepDirty: false });
              }}
            />
            <Button
              label={t('common.save')}
              type="submit"
              disabled={isCreating || isUpdating}
            />
          </Stack>
        </Footer>
      </form>

      <DataLossPrompt when={isDirty && !isCreating && !isUpdating} />
    </>
  );
};
