import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientDepositHistoryList } from 'containers/admin';

const AdminClientDepositHistoryPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientDepositHistoryList />
    </AdminClientLayout>
  );
};

export default AdminClientDepositHistoryPage;
