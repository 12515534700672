import { IAdminAdmin } from 'types/income';
import { converters as tableConverters } from 'store/table';
import { loaders } from './admin-admins.loaders';
import { AdminAdmin } from './admin-admins.types';

const convertAdmin = (admin: IAdminAdmin): AdminAdmin => {
  return {
    id: admin.id,
    firstName: admin.firstName,
    lastName: admin.lastName,
    fullName: [admin.firstName, admin.lastName].filter(Boolean).join(' '),
    email: admin.email,
    phone: admin.phone,
    status: admin.status,
    permissions: admin.melpPermissions,
  };
};

const convertAdmins = (clients: IAdminAdmin[]): AdminAdmin[] => {
  return clients.map(convertAdmin);
};

export const converters = {
  getAdmins: ({ data }: Awaited<ReturnType<typeof loaders.getAdmins>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertAdmins(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getAdmin: ({ data }: Awaited<ReturnType<typeof loaders.getAdmin>>) => {
    return convertAdmin(data);
  },
};
