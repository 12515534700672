import { NavMenuItem } from 'melp-design/components';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useLinks } from 'config';
import { PermissionOptions } from '../../../constants/Administrators';
import { useMe } from '../../../state/Administrators';
import { Permission } from '../../../types/Administrators';
import { LinkItem } from '../../../types/Common';

export default function MenuMap() {
  const location = useLocation();
  const { me, getPermissionsByModule, isRoot, isMelpAdmin } = useMe();
  const links = useLinks();

  const checkIfPermissionsAllowed = useCallback(
    (permissions: Permission[], matchAll?: boolean) => {
      if (matchAll) {
        const noPermissions = permissions.some(
          (permission: Permission) =>
            permission.permission === PermissionOptions.None,
        );
        return !noPermissions;
      }
      return permissions.some(
        (permission: Permission) =>
          permission.permission === 'view' || permission.permission === 'edit',
      );
    },
    [],
  );

  if (!me) return null;
  if (!isRoot && !me.permissions?.length && !isMelpAdmin) return null;

  const hasAccessToLink = (link: LinkItem) => {
    const hasCorrectRole = !!me?.role && link.roles.includes(me.role);

    if (hasCorrectRole) {
      if (isRoot) {
        return true;
      }

      if (!link.module) {
        return true;
      }

      const permissions = getPermissionsByModule(link.module);
      const permissionsAllowed = checkIfPermissionsAllowed(
        permissions,
        link.matchAllModules,
      );

      return permissionsAllowed;
    }

    return false;
  };

  return (
    <>
      {links.filter(hasAccessToLink).map((link) => {
        const childrenLinks = 'links' in link ? link.links : [];

        const isParentActive = () => {
          const trimLink =
            link.to?.split('/')[link.to.startsWith('/admin') ? 2 : 1];
          const includesParentLink = trimLink
            ? location.pathname.includes(trimLink)
            : false;

          const isChildActive = childrenLinks.some((childLink) =>
            location.pathname.includes(childLink.to),
          );

          return includesParentLink || isChildActive;
        };

        return (
          <NavMenuItem
            key={link.title}
            id={link.title}
            label={link.title}
            path={link.to}
            active={isParentActive()}
            badge={link.badge}
            childrenRoutes={childrenLinks
              .filter(hasAccessToLink)
              .map((childLink) => ({
                id: childLink.title,
                label: childLink.title,
                path: childLink.to,
                badge: childLink.badge,
              }))}
          />
        );
      })}
    </>
  );
}
