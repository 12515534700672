import { z } from 'zod';
import { adminAdminsFiltersSchema } from './admin-admins.schemas';

export const adminStatuses = ['active', 'inactive'] as const;
export type AdminStatus = (typeof adminStatuses)[number];

export const adminPermissions = [
  'clientCompanies',
  'templates',
  'integrations',
  'suppliers',
  'shopItems',
  'orders',
  'discounts',
  'reports',
  'melpAdmin',
] as const;
export type AdminPermission = (typeof adminPermissions)[number];

export interface AdminAdmin {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  status: AdminStatus;
  permissions: AdminPermission[];
}

export type AdminAdminsFilters = z.infer<typeof adminAdminsFiltersSchema>;
