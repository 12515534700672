import { z } from 'zod';
import { paginationFiltersSchema, sortFiltersSchema } from 'utils/schemas';
import { adminStatuses } from './admin-admins.types';

export const adminAdminsFiltersSchema = z
  .object({
    status: z.array(z.enum(adminStatuses)).catch([]),
    name: z.string().catch(''),
    phone: z.string().catch(''),
    email: z.string().catch(''),
  })
  .merge(sortFiltersSchema)
  .merge(paginationFiltersSchema);
