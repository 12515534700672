import {
  Button,
  ButtonOld,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Form,
  FormField,
  Typography,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContentLanguage } from 'types/general';
import { useUploadFile } from 'store/files';
import { ROUTES } from 'config';
import i18n from 'i18n';
import {
  NewsSendMethod,
  useCreateNew,
  useRemoveNew,
  useUpdateNew,
} from 'store/news';
import { Translations } from 'types/Common';
import { useCompanyLanguages } from 'state/Administrators';
import { api, makeRequest } from 'api/api';
import { Endpoints } from 'api/constants';
import { RecipientsResponse } from 'types/Recipients';
import { IRecognitionRecipient } from 'types/income';
import { useDialog } from 'utils/Dialog';
import { useLoading } from 'utils/Loading';
import { usePointsLabel } from 'containers/Recognition/common/usePointsLabel';
import DateRange from './DateRange';
import topRecipientsImagePath from './top-recipients.jpg';

type QuickRangeOptionType = 'thisWeek' | 'thisMonth' | 'lastMonth' | 'thisYear';
interface QuickRangeOptionDefinition {
  labelKey: string;
  getValues: () => {
    from: Moment;
    to: Moment;
  };
}

const quickRangeOptionDefinitions: Record<
  QuickRangeOptionType,
  QuickRangeOptionDefinition
> = {
  thisWeek: {
    labelKey: 'common.dateRangeQuickFilters-thisWeek',
    getValues: () => ({
      from: moment().startOf('week'),
      to: moment().endOf('week'),
    }),
  },
  thisMonth: {
    labelKey: 'common.dateRangeQuickFilters-thisMonth',
    getValues: () => ({
      from: moment().startOf('month'),
      to: moment().endOf('month'),
    }),
  },
  lastMonth: {
    labelKey: 'common.dateRangeQuickFilters-lastMonth',
    getValues: () => ({
      from: moment().subtract(1, 'month').startOf('month'),
      to: moment().subtract(1, 'month').endOf('month'),
    }),
  },
  thisYear: {
    labelKey: 'common.dateRangeQuickFilters-thisYear',
    getValues: () => ({
      from: moment().startOf('year'),
      to: moment().endOf('year'),
    }),
  },
};

const convertToQuickRangeOptionType = (from?: string, to?: string) => {
  if (!from || !to) {
    return;
  }

  const fromDate = moment(from);
  const toDate = moment(to);
  const options = Object.keys(
    quickRangeOptionDefinitions,
  ) as QuickRangeOptionType[];

  return options.find((type) => {
    const values = quickRangeOptionDefinitions[type].getValues();
    return fromDate.isSame(values.from) && toDate.isSame(values.to);
  });
};

const GenerateTopRecipientsNewsButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const pointsLabel = usePointsLabel();

  const creationDialog = useDialog();
  const generator = useTopRecipientsNewGenerator();

  const generateNews = async (data: NewsGeneratorParams) => {
    const response = await generator.generate(data);
    history.push(
      `${ROUTES.news.details.replace(':id', response.id)}?guided=true`,
    );
  };

  const quickRangeOptionTypes: QuickRangeOptionType[] = [
    'thisWeek',
    'thisMonth',
    'lastMonth',
    'thisYear',
  ];
  const quickRangeOptions =
    quickRangeOptionTypes.map((type) => ({
      type,
      ...quickRangeOptionDefinitions[type],
    })) ?? [];

  return (
    <>
      <Button
        label={t('recognition.generateTopRecipientsNews')}
        variant="tertiary"
        size="sm"
        onClick={creationDialog.openDialog}
      />

      <Dialog
        open={creationDialog.open}
        onClose={creationDialog.closeDialog}
        width={600}
      >
        <Form onSubmit={generateNews}>
          {({ setValue, watch }) => {
            const quickOptionHandler = (type: QuickRangeOptionType) => () => {
              const values = quickRangeOptionDefinitions[type].getValues();
              setValue('from', values.from.toISOString(true));
              setValue('to', values.to.toISOString(true));
            };

            const dateFromString = watch('from');
            const dateToString = watch('to');

            const isActive = (type: QuickRangeOptionType) => {
              const currentType = convertToQuickRangeOptionType(
                dateFromString,
                dateToString,
              );
              return type === currentType;
            };

            return (
              <>
                <DialogTitle>
                  {t('recognition.generateTopRecipientsNews')}
                </DialogTitle>
                <DialogContent>
                  <Stack gap="20px">
                    <Typography color="textSecondary">
                      {t('recognition.topRecipientsNewsExplanation')}
                    </Typography>
                    <Stack gap="5px">
                      <FormField
                        name="topCount"
                        render="number"
                        label={t('recognition.topRecipientsCount')}
                        defaultValue={10}
                        rules={{
                          required: true,
                          min: 1,
                          // matches positive integers
                          pattern: /^[1-9]\d*$/,
                        }}
                      />
                      <Typography variant="p3" color="textSecondary" pl="2px">
                        {t('recognition.topRecipientsCountHint')}
                      </Typography>
                    </Stack>
                    <Stack gap="10px">
                      <FormField
                        name="displayPoints"
                        render="switch"
                        label={t('recognition.topRecipientsDisplayPoints', {
                          pointsLabel,
                        })}
                        defaultValue={true}
                      />
                      <FormField
                        name="displayCount"
                        render="switch"
                        label={t('recognition.topRecipientsDisplayCount')}
                        defaultValue={true}
                      />
                    </Stack>
                    <Stack gap="10px">
                      <DateRange
                        from={{
                          name: 'from',
                          label: t('common.from'),
                          rules: { required: true },
                        }}
                        to={{
                          name: 'to',
                          label: t('common.to'),
                          rules: { required: true },
                        }}
                      />
                      <Stack direction="row" gap="10px">
                        {quickRangeOptions.map(({ type, ...quickOption }) => (
                          <ButtonOld
                            fullWidth
                            key={type}
                            onClick={quickOptionHandler(type)}
                            color={isActive(type) ? 'primary' : undefined}
                          >
                            {t(quickOption.labelKey)}
                          </ButtonOld>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <ButtonOld onClick={creationDialog.closeDialog}>
                    {t('common.cancel')}
                  </ButtonOld>
                  <ButtonOld
                    variant="contained"
                    color="primary"
                    type="submit"
                    loading={generator.loading}
                  >
                    {t('common.create')}
                  </ButtonOld>
                </DialogActions>
              </>
            );
          }}
        </Form>
      </Dialog>
    </>
  );
};

interface TopRecipient {
  name: string;
  points: number;
  count: number;
}

interface RequestPeriod {
  from: string;
  to: string;
}

interface TopRecipientsNewsParams {
  period: RequestPeriod;
  topRecipients: TopRecipient[];
  imageId: string;
  language: Uppercase<ContentLanguage>;
  pointsLabel: string;
  displayPoints: boolean;
  displayCount: boolean;
}

interface Update {
  name: string;
  sendMethods: NewsSendMethod[];
  translations: Translations<{
    title: string;
    content: string;
    headerImageId: string | null;
    attachmentIds: string[];
  }>;
}

class TopRecipientsNews implements Update {
  name;
  sendMethods = ['email' as const, 'appNotification' as const];
  translations;

  constructor({
    period: { from, to },
    language,
    topRecipients,
    ...params
  }: TopRecipientsNewsParams) {
    const period = {
      from: moment(from).format('l'),
      to: moment(to).format('l'),
    };

    const translateWithLangContext = (key: string, values?: object) =>
      i18n.t(key, { ...values, lng: language.toLowerCase() });

    this.name = translateWithLangContext(
      'recognition.topRecipientsNewsSystematicName',
      {
        interpolation: { escapeValue: false },
        ...period,
      },
    );

    const rangeOption = convertToQuickRangeOptionType(from, to);

    this.translations = [
      {
        language,
        title: translateWithLangContext('recognition.topRecipientsNewsTitle', {
          count: topRecipients.length,
        }),
        attachmentIds: [],
        content: translateWithLangContext(
          'recognition.topRecipientsNewsContent',
          {
            interpolation: { escapeValue: false },
            periodType: rangeOption,
            ...period,
            topRecipients: `<ol>${topRecipients
              .map((topRecipient) => {
                const getAdditionalInfoText = () => {
                  if (!params.displayPoints && !params.displayCount) {
                    return '';
                  }

                  const pointsText = params.displayPoints
                    ? `${topRecipient.points} ${params.pointsLabel}`
                    : '';
                  const countText = params.displayCount
                    ? translateWithLangContext(
                        'recognition.topRecipientsRecognizedTimes',
                        { count: topRecipient.count },
                      )
                    : '';

                  return ` (${[pointsText, countText]
                    .filter((text) => !!text.length)
                    .join(', ')})`;
                };

                return `<li>${
                  topRecipient.name
                }${getAdditionalInfoText()}</li>`;
              })
              .join('')}</ol>`,
          },
        ),
        headerImageId: params.imageId,
      },
    ];
  }
}

interface NewsGeneratorParams extends RequestPeriod {
  topCount: number;
  displayPoints: boolean;
  displayCount: boolean;
}

const useTopRecipientsNewGenerator = () => {
  const requestState = useLoading();
  const { defaultLanguage } = useCompanyLanguages();
  const pointsLabel = usePointsLabel();
  const { mutateAsync: uploadFile } = useUploadFile();
  const { mutateAsync: createNew } = useCreateNew();
  const { mutateAsync: updateNew } = useUpdateNew();
  const { mutateAsync: removeNew } = useRemoveNew();

  const generate = async (params: NewsGeneratorParams) => {
    let createdNewsId: string = '';
    requestState.startLoading();

    try {
      // Upload image
      const imageResponse = await fetch(topRecipientsImagePath);
      const imageBlob = await imageResponse.blob();
      const file = new File([imageBlob], 'top-recipients.jpeg');
      const { data } = await uploadFile({ file });

      // Load top recipients
      const recognitionRecipients = await api<{
        employees: IRecognitionRecipient[];
      }>({
        url: Endpoints.clientAdmin.recognitions.recipients.root,
        params: {
          page: 1,
          pageSize: params.topCount,
          sort: '-points',
          filter: {
            dateFrom: params.from,
            dateTo: params.to,
          },
        },
      });
      const topRecipients = recognitionRecipients.data.employees
        .filter((employee) => employee.points > 0)
        .map(({ points, count, ...employee }) => ({
          name: [employee.firstName, employee.lastName]
            .filter(Boolean)
            .join(' '),
          points,
          count,
        }));

      // Generate news data
      const newsData = new TopRecipientsNews({
        period: params,
        topRecipients,
        imageId: data.id,
        language: defaultLanguage,
        pointsLabel,
        displayPoints: params.displayPoints,
        displayCount: params.displayCount,
      });

      // Create news
      const { data: createdNew } = await createNew({
        name: newsData.name,
      });
      createdNewsId = createdNew.id;

      // Populate generated news data to created news
      const { data: updatedNew } = await updateNew({
        newId: createdNewsId,
        update: {
          name: newsData.name,
          sendMethods: newsData.sendMethods,
          translations: newsData.translations,
        },
      });

      // Set all employees as recipients
      const recipients = await makeRequest<RecipientsResponse>(
        'get',
        Endpoints.clientAdmin.news.byId.recipients.replace(
          ':newId',
          createdNewsId,
        ),
      );
      await makeRequest(
        'post',
        Endpoints.clientAdmin.news.byId.assignRecipients.replace(
          ':newId',
          createdNewsId,
        ),
        [
          {
            employeeGroupId: recipients.data.data[0].id,
            status: 'on',
          },
        ],
      );

      return updatedNew;
    } catch (e) {
      if (createdNewsId) {
        removeNew({ newId: createdNewsId });
      }
      throw e;
    } finally {
      requestState.stopLoading();
    }
  };

  return { generate, loading: requestState.loading };
};

export default GenerateTopRecipientsNewsButton;
