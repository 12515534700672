import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import {
  DetailsPageContent,
  DetailsPageHeader,
  SideMenu,
} from 'melp-design/components';
import { ROUTES } from 'config';
import { useRouteMatch } from 'react-router-dom';
import { useRouteParams } from 'utils/useIdParam';
import { useAdminAdmin } from 'store/admin-admins';
import { AdminAdminsActions } from 'containers/admin/admins/atoms';

export const AdminAdminLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const { id } = useRouteParams(ROUTES.admin.admins.details);

  const { data: admin, isInitialLoading } = useAdminAdmin(id);

  return (
    <Stack>
      <DetailsPageHeader
        title={
          isInitialLoading
            ? ''
            : admin
            ? admin.fullName
            : t('administrators.add_administrator')
        }
        side={
          admin ? (
            <AdminAdminsActions id={admin.id} name={admin.fullName} />
          ) : null
        }
        backTo={ROUTES.admin.admins.list}
      />

      <DetailsPageContent>
        <SideMenu
          tabs={[
            {
              key: 'details',
              label: t('common.settings'),
              url: ROUTES.admin.admins.details.replace(':id', id),
              isActive:
                path === ROUTES.admin.admins.details ||
                path === ROUTES.admin.admins.create,
              disabled: !admin,
            },
          ]}
        />
        {children}
      </DetailsPageContent>
    </Stack>
  );
};
