import { z } from 'zod';
import { dateRangeSchema, paginationFiltersSchema } from 'utils/schemas';
import { companyStatuses } from 'store/companies';

export const adminClientsFiltersSchema = z
  .object({
    status: z.enum(companyStatuses).nullable().catch(null),
    companies: z.array(z.string()).catch([]),
    name: z.string().catch(''),
    date: dateRangeSchema,
  })
  .merge(paginationFiltersSchema);

export const adminCompaniesFiltersSchema = z
  .object({
    name: z.string().catch(''),
  })
  .merge(paginationFiltersSchema);
