import { FC, useState } from 'react';
import { useAdminCompanies } from 'store/admin-clients';
import { SelectFilter, SelectFilterProps } from 'components/filters';

type Props = Omit<
  SelectFilterProps<string, true>,
  'options' | 'loadingOptions' | 'asyncSearch' | 'onSearch' | 'multiple'
>;

export const AdminCompaniesFilter: FC<Props> = (props) => {
  const [search, setSearch] = useState('');

  const { data: companies, isLoading } = useAdminCompanies({
    name: search,
  });

  return (
    <SelectFilter
      {...props}
      options={companies?.items.map((company) => ({
        key: company.id,
        label: company.name,
      }))}
      loadingOptions={isLoading}
      onSearch={setSearch}
      multiple
      asyncSearch
    />
  );
};
