import { z } from 'zod';
import { ExpenseType, expenseTypes } from 'store/expenses';
import {
  adminCompanyExpensesFiltersSchema,
  adminClientDepositsFiltersSchema,
  adminDepositEventsFiltersSchema,
  adminDepositHistoryFiltersSchema,
  adminDepositBalancesPerClientFiltersSchema,
  adminDepositBalancesPerCompanyFiltersSchema,
} from './admin-client-deposits.schemas';

export const clientCompanyBalanceTypes = [...expenseTypes, 'deposit'] as const;
export type ClientCompanyBalanceType =
  (typeof clientCompanyBalanceTypes)[number];

export const clientCompanyDepositHistoryTypes = [
  'addDeposit',
  'deductDeposit',
] as const;
export type ClientCompanyDepositHistoryType =
  (typeof clientCompanyDepositHistoryTypes)[number];

export interface AdminClientDeposit {
  id: string;
  name: string;
  averageMonthlyTurnover: number;
  remainingDeposit: number;
  credit: number;
  lowDepositAlert: number;
}

export interface AdminClientExpense {
  id: string;
  date: string;
  type: ExpenseType;
  amount: number;
  description: string;
}

export interface DepositsMeta {
  totalCredit: number;
  totalRemainingDeposit: number;
  lowDepositAlert: number | null;
}

export interface AdminDepositEvent {
  id: string;
  type: ExpenseType;
  startsAt: {
    date: string;
    admin: {
      id: string;
      name: string;
      email: string;
    };
  };
  endsAt: {
    date: string;
    admin: {
      id: string;
      name: string;
      email: string;
    };
  } | null;
  parentCompany: {
    id: string;
    name: string;
  };
}

export interface AdminDepositHistory {
  id: string;
  type: ClientCompanyDepositHistoryType;
  company: {
    id: string;
    name: string;
  } | null;
  admin: {
    id: string;
    name: string;
  };
  amount: number;
  credit: number;
  invoice: string;
  date: string;
  comment: string;
}

export interface AdminDepositBalancesPerClient {
  id: string;
  client: {
    id: string;
    name: string;
  };
  remainingDeposit: number;
  credit: number;
  lowDepositAlert: number;
}

export interface AdminDepositBalancesPerCompany {
  id: string;
  clientId: string;
  company: {
    id: string;
    name: string;
  };
  remainingDeposit: number;
  credit: number;
  lowDepositAlert: number;
}

export interface DepositPendingActions {
  clients: number;
  companies: number;
}

export type AdminClientDepositsFilters = z.infer<
  typeof adminClientDepositsFiltersSchema
>;

export type AdminCompanyExpensesFilters = z.infer<
  typeof adminCompanyExpensesFiltersSchema
>;

export type AdminDepositEventsFilters = z.infer<
  typeof adminDepositEventsFiltersSchema
>;

export type AdminDepositHistoryFilters = z.infer<
  typeof adminDepositHistoryFiltersSchema
>;

export type AdminDepositBalancesPerClientFilters = z.infer<
  typeof adminDepositBalancesPerClientFiltersSchema
>;

export type AdminDepositBalancesPerCompanyFilters = z.infer<
  typeof adminDepositBalancesPerCompanyFiltersSchema
>;
