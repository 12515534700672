import { Controller, SubmitHandler, useForm } from 'react-hook-form-latest';
import {
  Button,
  CheckboxInput,
  Footer,
  Loader,
  Panel,
  SelectInput,
} from 'melp-design/components';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from 'utils/useIdParam';
import { FC, useEffect } from 'react';
import { DiscountPackage, discountPackages } from 'store/admin-discounts';
import {
  ClientFeature,
  clientFeatures,
  useAdminClient,
  useAdminUpdateClient,
} from 'store/admin-clients';
import { ROUTES } from 'config';
import { useSearchParams } from 'utils/navigation';
import { adminDepositEventsFiltersSchema } from 'store/admin-client-deposits';
import { usePredefinedToasts } from 'utils/Toast';

interface Values {
  features: Record<ClientFeature, boolean>;
  package: DiscountPackage;
}

const INITIAL_VALUES: Values = {
  features: {
    benefits: false,
    news: false,
    recognition: false,
    marketplace: false,
    claimReimbursement: false,
    companyItems: false,
    discounts: false,
    manualExpenses: false,
  },
  package: 'basic',
};

export const AdminClientFeatures: FC = () => {
  const { t } = useTranslation();
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.features,
  );
  const { navigate } = useSearchParams(adminDepositEventsFiltersSchema);
  const predefinedToasts = usePredefinedToasts();

  const { data: client, isLoading } = useAdminClient(clientId);

  const { mutate: updateClient, isLoading: isUpdating } =
    useAdminUpdateClient();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<Values>({ defaultValues: INITIAL_VALUES });

  useEffect(() => {
    if (client) {
      reset({
        features: clientFeatures.reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: client.features.includes(cur),
          }),
          {},
        ),
        package: client.settings.discountPlan,
      });
    }
  }, [client, reset]);

  const [features] = watch(['features']);

  const onSubmit: SubmitHandler<Values> = (values) => {
    updateClient(
      {
        clientId,
        data: {
          features: clientFeatures.filter(
            (feature) => !!values.features[feature],
          ),
          discountPlan: values.package,
        },
      },
      {
        onSuccess: () => {
          predefinedToasts.success.updated();
        },
      },
    );
  };

  if (isLoading) return <Loader />;
  if (!client) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Panel title={t('sidebar.features')}>
        <Stack spacing={3}>
          <Stack gap={1.5}>
            <Controller
              name="features.benefits"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-benefits')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.news"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-news')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.recognition"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-recognition')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.marketplace"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-marketplace')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
            <Box>
              <Button
                label={t('deposits.actions.manage')}
                size="sm"
                variant="tertiary"
                disabled={!features.marketplace}
                onClick={() => {
                  navigate({
                    url: ROUTES.admin.clients.details.depositEvents.list.replace(
                      ':id',
                      clientId,
                    ),
                    params: { type: ['shopOrder'] },
                  });
                }}
              />
            </Box>
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.companyItems"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-companyItems')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
            <Box>
              <Button
                label={t('deposits.actions.manage')}
                size="sm"
                variant="tertiary"
                disabled={!features.companyItems}
                onClick={() => {
                  navigate({
                    url: ROUTES.admin.clients.details.depositEvents.list.replace(
                      ':id',
                      clientId,
                    ),
                    params: { type: ['companyItem'] },
                  });
                }}
              />
            </Box>
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.claimReimbursement"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-claimReimbursement')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
            <Box>
              <Button
                label={t('deposits.actions.manage')}
                size="sm"
                variant="tertiary"
                disabled={!features.claimReimbursement}
                onClick={() => {
                  navigate({
                    url: ROUTES.admin.clients.details.depositEvents.list.replace(
                      ':id',
                      clientId,
                    ),
                    params: { type: ['claimReimbursement'] },
                  });
                }}
              />
            </Box>
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.manualExpenses"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('expenses.filters.type.options.manual')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
            <Box>
              <Button
                label={t('deposits.actions.manage')}
                size="sm"
                variant="tertiary"
                disabled={!features.manualExpenses}
                onClick={() => {
                  navigate({
                    url: ROUTES.admin.clients.details.depositEvents.list.replace(
                      ':id',
                      clientId,
                    ),
                    params: { type: ['manual'] },
                  });
                }}
              />
            </Box>
          </Stack>

          <Stack gap={1.5}>
            <Controller
              name="features.discounts"
              control={control}
              render={({ field: { name, value, onChange, ref } }) => (
                <CheckboxInput
                  label={t('clients.feature-discounts')}
                  name={name}
                  checked={value}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
            {features.discounts ? (
              <Stack flexDirection="row" pl={3}>
                <Controller
                  name="package"
                  control={control}
                  render={({ field: { name, value, onChange, ref } }) => (
                    <SelectInput
                      label={t('discounts.package.label')}
                      name={name}
                      value={value}
                      onChange={onChange}
                      options={discountPackages
                        .filter((pack) => pack !== 'internal')
                        .map((pack) => ({
                          label: t(`discounts.package.${pack}`),
                          value: pack,
                        }))}
                      required
                      ref={ref}
                    />
                  )}
                />
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Panel>

      <Footer visible={isDirty}>
        <Stack direction="row" justifyContent="end" gap="10px">
          <Button
            label={t('common.cancel')}
            variant="neutral-outline"
            onClick={() => {
              reset(undefined, { keepDirty: false });
            }}
          />
          <Button
            label={t('common.save')}
            variant="primary"
            type="submit"
            disabled={isUpdating}
          />
        </Stack>
      </Footer>
    </form>
  );
};
