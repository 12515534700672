import { FC } from 'react';
import {
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
} from 'melp-design/components';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSearchParams } from 'utils/navigation';
import {
  adminCompanyExpensesFiltersSchema,
  useAdminCompanyExpenses,
} from 'store/admin-client-deposits';
import { formatCurrency } from 'utils/format';
import { APP, ROUTES } from 'config';
import {
  DateRangeFilter,
  FilterButton,
  FiltersInModal,
  SelectFilter,
} from 'components/filters';
import { useRouteParams } from 'utils/useIdParam';
import { expenseTypes } from 'store/expenses';

export const AdminClientCompanyExpensesList: FC = () => {
  const { t, i18n } = useTranslation();
  const { companyId } = useRouteParams(
    ROUTES.admin.clients.details.expenses.details,
  );

  const { searchParams: filters, navigate } = useSearchParams(
    adminCompanyExpensesFiltersSchema,
  );

  const { data: expense, isLoading } = useAdminCompanyExpenses(
    companyId,
    filters,
  );

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <ListTotal total={expense?.total} />

          <Stack direction="row" alignItems="center" gap={1}>
            <FiltersInModal
              value={filters}
              initialValues={adminCompanyExpensesFiltersSchema.parse({})}
              onChange={(v) => navigate({ params: v })}
            >
              {({ value, initialValues, setFilter }) => (
                <>
                  <FilterButton
                    label={t('integrations.type')}
                    value={value.type}
                    initialValue={initialValues.type}
                    onChange={(v) => setFilter('type', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <SelectFilter
                        value={value}
                        onApplyFilter={applyFilter}
                        onClearFilter={clearFilter}
                        onCancel={close}
                        multiple
                        searchable={false}
                        options={expenseTypes.map((type) => ({
                          key: type,
                          label: t(`expenses.filters.type.options.${type}`),
                        }))}
                      />
                    )}
                  </FilterButton>

                  <FilterButton
                    label={t('common.date')}
                    value={value.date}
                    initialValue={initialValues.date}
                    onChange={(v) => setFilter('date', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <DateRangeFilter
                        value={value}
                        onApply={applyFilter}
                        onClear={clearFilter}
                        onCancel={close}
                        suggestions={['week', 'month', 'quarter', 'year']}
                      />
                    )}
                  </FilterButton>
                </>
              )}
            </FiltersInModal>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={expense?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'date',
            header: t('common.date'),
            cell: ({ row: { original: expense } }) => {
              return moment(expense.date).format('l');
            },
          },
          {
            id: 'type',
            header: t('integrations.type'),
            cell: ({ row: { original: expense } }) => {
              return t(`expenses.filters.type.options.${expense.type}`);
            },
          },
          {
            id: 'amount',
            header: t('expenses.title'),
            cell: ({ row: { original: expense } }) => {
              return formatCurrency(i18n.language, expense.amount);
            },
            meta: { align: 'right' },
          },
          {
            id: 'description',
            header: t('clients.comment'),
            cell: ({ row: { original: expense } }) => {
              return expense.description;
            },
          },
        ]}
        pagination={
          expense && {
            count: expense.total,
            page: filters.page,
            pageSize: filters.pageSize,
            pageSizeOptions: APP.pagination.defaults.pageSize,
            onChange: (page, pageSize) => {
              navigate({ params: { page, pageSize } });
            },
          }
        }
      />
    </TableWrapper>
  );
};
