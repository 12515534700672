import { ComponentPropsWithoutRef, useRef } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnDef,
  RowSelectionState,
  Row,
  RowData,
  TableOptions,
} from '@tanstack/react-table';
import {
  Box,
  ButtonBase,
  Checkbox,
  Stack,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@mui/material';
import { HelpOutline } from '@mui/icons-material';
import { LIST_TOOLBAR_ID, Loader, NoData } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import { SortAsc, SortDesc, Sort as SortIcon } from 'melp-design/icons';
import { SortOrder } from 'components/filters/Types';
import { useBoundingClientRect } from 'utils/hooks';
import Pagination from '../Pagination/Pagination';
import { useSelectedRows } from './table.hooks';
import { Floater } from './atoms';

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: 'left' | 'center' | 'right';
    sort?: boolean;
    info?: string;
  }
}

type Sort = {
  columnKey: string;
  order: SortOrder;
} | null;

interface Props<T extends { id: string }> {
  data: T[];
  columns: ColumnDef<T>[];
  isLoading?: boolean;
  pagination?: ComponentPropsWithoutRef<typeof Pagination>;
  selection?: {
    value: RowSelectionState;
    onChange: TableOptions<T>['onRowSelectionChange'];
    actions?: Array<{ label: string; onClick: () => void }>;
    isLoading?: boolean;
  };
  sort?: {
    value: Sort;
    onChange: (value: Sort) => void;
  };
  rows?: {
    inactive?: (row: Row<T>) => boolean;
    color?: (row: Row<T>) => string | undefined;
  };
  stickyHeader?: boolean;
}

export const Table = <T extends { id: string }>({
  data,
  columns,
  isLoading,
  selection,
  pagination,
  sort,
  rows,
  stickyHeader,
}: Props<T>) => {
  const ref = useRef<HTMLElement>(null);
  const containerRect = useBoundingClientRect(ref.current);
  const toolbarRect = useBoundingClientRect(
    document.getElementById(LIST_TOOLBAR_ID),
  );

  const table = useReactTable({
    data,
    columns: !!selection
      ? [
          {
            id: 'select',
            header: ({ table }) => (
              <Checkbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
                color="primary"
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
                color="primary"
              />
            ),
          },
          ...columns,
        ]
      : columns,
    state: {
      rowSelection: selection?.value,
    },
    getRowId: ({ id }) => id,
    enableRowSelection: !!selection,
    onRowSelectionChange: selection?.onChange,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box sx={{ pb: 15 }} ref={ref}>
      {isLoading ? (
        <Box className="TableLoader">
          <Loader />
        </Box>
      ) : data.length ? (
        <>
          <Box
            sx={{
              position: 'relative',
              // horizontal scrolling is not available with "position: sticky"
              // https://github.com/w3c/csswg-drafts/issues/865#issuecomment-350585274
              ...(stickyHeader ? {} : { overflowX: 'auto' }),
            }}
          >
            <MuiTable
              stickyHeader={stickyHeader}
              sx={{
                ...(selection
                  ? {
                      '.MuiTableCell-root:first-of-type': {
                        padding: 1,
                        paddingRight: 0,
                        width: 48,
                      },
                      '.MuiTableCell-head:first-of-type': {
                        paddingTop: 0.5,
                        verticalAlign: 'top',
                      },
                    }
                  : {}),
              }}
            >
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const { columnDef } = header.column;

                      const Icon =
                        !sort || sort.value?.columnKey !== columnDef.id
                          ? SortIcon
                          : sort.value?.order === 'asc'
                          ? SortAsc
                          : SortDesc;

                      const title =
                        columnDef.meta?.sort && columnDef.id && sort ? (
                          <ButtonBase
                            onClick={() => {
                              const columnKey = columnDef.id ?? '';
                              sort.onChange?.(
                                columnKey !== sort.value?.columnKey
                                  ? { columnKey, order: 'asc' }
                                  : sort.value.order === 'desc'
                                  ? null
                                  : { columnKey, order: 'desc' },
                              );
                            }}
                            disableRipple
                            sx={{
                              fontWeight: 'inherit',
                              lineHeight: 'inherit',
                              '&:hover svg': {
                                color: SystemColors.black,
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="baseline"
                              gap={1}
                            >
                              {columnDef.header}
                              <Icon style={{ height: '10px', flexShrink: 0 }} />
                            </Stack>
                          </ButtonBase>
                        ) : (
                          columnDef.header
                        );

                      const headerCell = columnDef.meta?.info ? (
                        <>
                          {title}
                          <Tooltip title={columnDef.meta.info}>
                            <HelpOutline
                              sx={{
                                width: 18,
                                height: 18,
                                verticalAlign: 'middle',
                                ml: 0.5,
                              }}
                            />
                          </Tooltip>
                        </>
                      ) : (
                        title
                      );

                      return (
                        <TableCell
                          sx={{
                            verticalAlign: 'top',
                            textAlign: columnDef.meta?.align,
                            top: toolbarRect?.height,
                          }}
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(headerCell, header.getContext())}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => {
                  const inactive = rows?.inactive?.(row);
                  const rowColor = rows?.color?.(row);

                  return (
                    <TableRow
                      hover
                      sx={{
                        ...(rowColor
                          ? {
                              background: rowColor,
                              '&.MuiTableRow-hover:hover': {
                                background: rowColor,
                                opacity: 0.9,
                              },
                            }
                          : {}),
                        boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 4px inset',
                      }}
                      key={row.id}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          sx={{
                            color: inactive ? SystemColors.grey[55] : undefined,
                            textAlign: cell.column.columnDef.meta?.align,
                          }}
                          key={cell.id}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </MuiTable>

            {selection?.actions && Object.keys(selection.value).length ? (
              <Floater
                selectedItems={Object.keys(selection.value)}
                actions={selection.actions}
                onClear={() => selection.onChange?.({})}
                isLoading={selection.isLoading}
                offsetLeft={containerRect?.x}
              />
            ) : null}
          </Box>

          {pagination ? (
            <Box mt={2}>
              <Pagination {...pagination} />
            </Box>
          ) : null}
        </>
      ) : (
        <NoData />
      )}
    </Box>
  );
};

Table.useSelectedRows = useSelectedRows;
