import { Button } from 'melp-design/components';
import { Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleDate } from 'types/Common';

interface Props {
  value?: SingleDate;
  onApply: (value: SingleDate) => void;
  onClear: () => void;
  onCancel: () => void;
  withTimezone?: boolean;
}

export const DateFilter: FC<Props> = ({
  value,
  withTimezone = false,
  onApply,
  onClear,
  onCancel,
}) => {
  const { t, i18n } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
    value ? moment(value).startOf('day') : null,
  );
  const [isOpen, setIsOpen] = useState(false);

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={i18n.language}
    >
      <Stack gap={1} p={1} minWidth="300px">
        <DatePicker
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              fullWidth: true,
              placeholder: moment.localeData().longDateFormat('L'),
              onClick: () => setIsOpen((prev) => !prev),
            },
            popper: { style: { zIndex: 1501 } },
          }}
          value={selectedDate}
          onChange={(value) => setSelectedDate(value?.startOf('day') ?? null)}
          onClose={() => setIsOpen(false)}
          open={isOpen}
        />

        <Stack direction="row" justifyContent="end" gap={1}>
          {value ? (
            <Button
              variant="neutral-outline"
              label={t('table.clear')}
              onClick={onClear}
            />
          ) : null}
          <Button
            variant="neutral-outline"
            label={t('common.cancel')}
            onClick={onCancel}
          />
          <Button
            label={t('common.applyFilter')}
            variant="primary"
            onClick={() => {
              const date = selectedDate?.toISOString(true) ?? null;

              onApply(withTimezone ? date : date?.split('T')[0] ?? null);
            }}
            disabled={!selectedDate}
          />
        </Stack>
      </Stack>
    </LocalizationProvider>
  );
};
