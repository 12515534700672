import { z } from 'zod';
import { orderBy } from 'lodash';
import { converters as tableConverters } from 'store/table';
import {
  IAdminCompanyExpense,
  IAdminClientDeposit,
  IAdminDepositEvent,
  IAdminDepositHistory,
  IAdminDepositsTotals,
  IAdminDepositBalancesPerClient,
  IAdminDepositBalancesPerCompany,
  IDepositPendingActions,
} from 'types/income';
import { sortFiltersSchema } from 'utils/schemas';
import { convertExpenseType } from 'store/expenses';
import { loaders } from './admin-client-deposits.loaders';
import {
  AdminClientExpense,
  AdminClientDeposit,
  AdminDepositEvent,
  DepositsMeta,
  AdminDepositHistory,
  AdminDepositBalancesPerClient,
  AdminDepositBalancesPerCompany,
  DepositPendingActions,
} from './admin-client-deposits.types';

const convertDeposit = (
  deposit: IAdminClientDeposit['data'][number],
): AdminClientDeposit => {
  return {
    id: deposit.companyId,
    name: deposit.companyName,
    averageMonthlyTurnover: deposit.averageMonthlyTurnover,
    credit: deposit.credit ?? 0,
    remainingDeposit: deposit.remainingDeposit ?? 0,
    lowDepositAlert: deposit.lowDepositAlert ?? 0,
  };
};

const convertDeposits = (
  deposits: IAdminClientDeposit['data'],
): AdminClientDeposit[] => {
  return deposits.map(convertDeposit);
};

const convertDepositTotals = (deposits: IAdminDepositsTotals): DepositsMeta => {
  return {
    totalCredit: deposits.totalCredit,
    totalRemainingDeposit: deposits.totalRemainingDeposit,
    lowDepositAlert: deposits.lowDepositAlert ?? null,
  };
};

const convertExpense = (expense: IAdminCompanyExpense): AdminClientExpense => {
  return {
    id: expense.id,
    type: convertExpenseType(expense.type),
    date: expense.date,
    amount: expense.expense,
    description: expense.description,
  };
};

const convertExpenses = (
  expenses: IAdminCompanyExpense[],
): AdminClientExpense[] => {
  return expenses.map(convertExpense);
};

const convertDepositEvent = (event: IAdminDepositEvent): AdminDepositEvent => {
  return {
    id: event.id,
    type: convertExpenseType(event.type),
    startsAt: {
      date: event.startDate,
      admin: event.startDateChangedByAdmin,
    },
    endsAt: event.endDate
      ? {
          date: event.endDate,
          admin: event.endDateChangedByAdmin,
        }
      : null,
    parentCompany: event.parentCompany,
  };
};

const convertDepositEvents = (
  events: IAdminDepositEvent[],
): AdminDepositEvent[] => {
  return events.map(convertDepositEvent);
};

const convertDepositHistoryEntry = (
  entry: IAdminDepositHistory,
): AdminDepositHistory => {
  return {
    id: entry.id,
    type: entry.type,
    company: entry.companyId
      ? {
          id: entry.companyId,
          name: entry.companyName ?? '',
        }
      : null,
    admin: {
      id: entry.adminId,
      name: entry.adminName,
    },
    amount: entry.amount,
    credit: entry.credit ?? 0,
    invoice: entry.invoiceNumber,
    date: entry.date,
    comment: entry.comment,
  };
};

const convertDepositHistory = (
  entries: IAdminDepositHistory[],
): AdminDepositHistory[] => {
  return entries.map(convertDepositHistoryEntry);
};

const convertDepositBalancePerClient = (
  balance: IAdminDepositBalancesPerClient,
): AdminDepositBalancesPerClient => {
  return {
    id: balance.parentCompanyId,
    client: {
      id: balance.parentCompanyId,
      name: balance.parentCompanyName,
    },
    credit: balance.credit,
    remainingDeposit: balance.remainingDeposit,
    lowDepositAlert: balance.lowDepositAlert,
  };
};

const convertDepositBalancesPerClient = (
  balances: IAdminDepositBalancesPerClient[],
): AdminDepositBalancesPerClient[] => {
  return balances.map(convertDepositBalancePerClient);
};

const convertDepositBalancePerCompany = (
  balance: IAdminDepositBalancesPerCompany,
): AdminDepositBalancesPerCompany => {
  return {
    id: balance.companyId,
    company: {
      id: balance.companyId,
      name: balance.companyName,
    },
    clientId: balance.parentCompanyId,
    credit: balance.credit,
    remainingDeposit: balance.remainingDeposit,
    lowDepositAlert: balance.lowDepositAlert,
  };
};

const convertDepositBalancesPerCompany = (
  balances: IAdminDepositBalancesPerCompany[],
): AdminDepositBalancesPerCompany[] => {
  return balances.map(convertDepositBalancePerCompany);
};

const convertPendingActions = (
  actions: IDepositPendingActions,
): DepositPendingActions => {
  return {
    clients: actions.clients,
    companies: actions.companies,
  };
};

export const converters = {
  getDeposits: (
    { data }: Awaited<ReturnType<typeof loaders.getDeposits>>,
    sort?: z.infer<typeof sortFiltersSchema>['sort'],
  ) => {
    const items = convertDeposits(data.data);

    return {
      items: sort ? orderBy(items, [sort.columnKey], [sort.order]) : items,
      meta: convertDepositTotals(data),
    };
  },
  getCompanyExpenses: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getCompanyExpenses>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertExpenses(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDepositEvents: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepositEvents>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertDepositEvents(data.data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDepositHistory: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepositHistory>>) => {
    const { total, page, pageSize, pages } = tableConverters.getTableData(data);

    return {
      items: convertDepositHistory(data.data),
      meta: convertDepositTotals(data),
      total,
      page,
      pageSize,
      pages,
    };
  },
  getDepositBalancesPerClient: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepositBalancesPerClient>>) => {
    return {
      items: convertDepositBalancesPerClient(data.data),
      total: data.data.length,
    };
  },
  getDepositBalancesPerCompany: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getDepositBalancesPerCompany>>) => {
    return {
      items: convertDepositBalancesPerCompany(data.data),
      total: data.data.length,
    };
  },
  getPendingActions: ({
    data,
  }: Awaited<ReturnType<typeof loaders.getPendingActions>>) => {
    return convertPendingActions(data);
  },
};
