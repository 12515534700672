import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './admin-admins.loaders';
import { adminAdminsKeys } from './admin-admins.queries';

export const useAdminCreateAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createAdmin, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminAdminsKeys.all);
    },
  });
};

export const useAdminUpdateAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateAdmin, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminAdminsKeys.all);
    },
  });
};

export const useAdminDeleteAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteAdmin, {
    onSuccess: () => {
      return queryClient.invalidateQueries(adminAdminsKeys.lists);
    },
  });
};
