import { FC } from 'react';
import { Loader, NothingFoundAlert } from 'melp-design/components';
import { useRouteParams } from 'utils/useIdParam';
import { ROUTES } from 'config';
import { useAdminClient } from 'store/admin-clients';
import {
  AdminClientDepositHistoryListPerClient,
  AdminClientDepositHistoryListPerCompany,
} from './atoms';

export const AdminClientDepositHistoryList: FC = () => {
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.deposits.list,
  );

  const { data: client, isLoading } = useAdminClient(clientId);

  if (isLoading) return <Loader />;
  if (!client) return <NothingFoundAlert />;

  return client.settings.depositManagementMethod === 'clientAccount' ? (
    <AdminClientDepositHistoryListPerClient />
  ) : client.settings.depositManagementMethod === 'company' ? (
    <AdminClientDepositHistoryListPerCompany />
  ) : null;
};
