import { ClientFeature, DepositManagementMethod } from 'store/admin-clients';
import { DiscountPackage } from 'store/admin-discounts';
import { PaginationFilters } from 'types/Common';
import { ContentLanguage, Currency } from 'types/general';

export const companyStatuses = ['active', 'inactive'] as const;
export type CompanyStatus = (typeof companyStatuses)[number];

export interface BaseCompany {
  id: string;
  name: string;
}

export interface Company {
  id: string;
  name: string;
  status: CompanyStatus;
  parentCompanyId: string;
  companyGroups: string[];
  employeeCount: number;
  departmentCount: number;
}

export interface BaseParentCompany {
  id: string;
  country: string;
  internalComment: string;
  name: string;
  status: CompanyStatus;
  activeEmployees: number;
  activeMobileUsers: number;
  inactiveEmployees: number;
}

export interface ParentCompany extends BaseParentCompany {
  code: string;
  defaultCurrency: Currency;
  creationDate: string;
  deactivationDate: string | null;
  supportedLanguages: Uppercase<ContentLanguage>[];
  features: ClientFeature[];
  settings: {
    defaultLanguage: Uppercase<ContentLanguage>;
    isPersonalCodeRequiredForLogin: boolean;
    isEmailVisibleInternally: boolean;
    discountPlan: DiscountPackage;
    depositManagementMethod: DepositManagementMethod;
  };
}

export type ParentCompanyWithStats = Pick<
  ParentCompany,
  'id' | 'name' | 'country' | 'internalComment' | 'status'
> & {
  activeEmployees: number;
  activeMobileUsers: number;
  inactiveEmployees: number;
};

export interface Department {
  id: string;
  name: string;
}

export interface ParentCompaniesFilters extends PaginationFilters {}
