import { FC } from 'react';
import {
  Link,
  ListTotal,
  Table,
  TableToolbar,
  TableWrapper,
  Tile,
} from 'melp-design/components';
import { Divider, Skeleton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRouteParams } from 'utils/useIdParam';
import { useSearchParams } from 'utils/navigation';
import {
  adminClientDepositsFiltersSchema,
  useAdminClientDeposits,
} from 'store/admin-client-deposits';
import { formatCurrency } from 'utils/format';
import { ROUTES } from 'config';
import { DateFilter, FilterButton, Filters } from 'components/filters';
import { calculateFiltersTotal } from 'utils/Filters';
import { AdminClientDepositsActions } from 'containers/admin';

export const AdminClientDepositsListPerClient: FC = () => {
  const { t, i18n } = useTranslation();
  const { id: clientId } = useRouteParams(
    ROUTES.admin.clients.details.deposits.list,
  );

  const { searchParams: filters, navigate } = useSearchParams(
    adminClientDepositsFiltersSchema,
  );

  const { data: deposits, isLoading } = useAdminClientDeposits(
    clientId,
    filters,
  );

  return (
    <TableWrapper>
      <TableToolbar>
        <Stack gap="10px">
          <Stack direction="row" alignItems="center" gap="15px">
            {isLoading ? (
              <>
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
                <Skeleton variant="rounded" width={200} height={81} />
              </>
            ) : deposits ? (
              <>
                <Tile
                  title={t('clients.deposits.balance')}
                  items={[
                    formatCurrency(
                      i18n.language,
                      deposits.meta.totalRemainingDeposit,
                    ),
                  ]}
                />
                <Tile
                  title={t('common.credit.label')}
                  items={[
                    formatCurrency(i18n.language, deposits.meta.totalCredit),
                  ]}
                />
                {deposits.meta.lowDepositAlert !== null ? (
                  <Tile
                    title={t('clients.deposits.low_deposit_alert.label')}
                    items={[
                      formatCurrency(
                        i18n.language,
                        deposits.meta.lowDepositAlert,
                      ),
                    ]}
                  />
                ) : null}
              </>
            ) : null}
          </Stack>

          <Divider />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap="10px"
          >
            <ListTotal total={deposits?.items.length} />

            <Stack direction="row" alignItems="center" gap="10px">
              <Filters
                value={filters}
                initialValues={adminClientDepositsFiltersSchema.parse({})}
                onChange={(v) => navigate({ params: v })}
                showClearButton={!!calculateFiltersTotal(filters)}
              >
                {({ value, initialValues, setFilter }) => (
                  <FilterButton
                    label={t('common.date')}
                    value={value.date}
                    initialValue={initialValues.date}
                    onChange={(v) => setFilter('date', v)}
                  >
                    {({ value, applyFilter, clearFilter, close }) => (
                      <DateFilter
                        value={value}
                        onApply={applyFilter}
                        onClear={clearFilter}
                        onCancel={close}
                      />
                    )}
                  </FilterButton>
                )}
              </Filters>

              <Divider orientation="vertical" variant="middle" flexItem />

              <AdminClientDepositsActions
                clientId={clientId}
                companyId=""
                credit={deposits?.meta.totalCredit ?? 0}
              />
            </Stack>
          </Stack>
        </Stack>
      </TableToolbar>

      <Table
        data={deposits?.items ?? []}
        isLoading={isLoading}
        columns={[
          {
            id: 'name',
            header: t('employees.company'),
            cell: ({ row: { original: deposit } }) => {
              return (
                <Link
                  label={deposit.name}
                  to={ROUTES.admin.clients.details.expenses.details
                    .replace(':id', clientId)
                    .replace(':companyId', deposit.id)}
                />
              );
            },
          },
          {
            id: 'averageMonthlyTurnover',
            header: t('clients.deposits.average_monthly_turnover.label'),
            cell: ({ row: { original: deposit } }) => {
              return formatCurrency(
                i18n.language,
                deposit.averageMonthlyTurnover,
              );
            },
            meta: { align: 'right' },
          },
        ]}
      />
    </TableWrapper>
  );
};
