import { FC } from 'react';
import { Box, Container } from '@mui/material';
import { useAuthContext } from 'auth';
import Header from '../../header/Header/Header';

export const Base: FC = ({ children }) => {
  const { loggedIn } = useAuthContext();

  return (
    <Box>
      {loggedIn && <Header />}
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          paddingTop: '30px',
          paddingBottom: '30px',
          '&.MuiContainer-maxWidthLg': {
            maxWidth: 1260,
          },
        }}
      >
        {children}
      </Container>
    </Box>
  );
};
