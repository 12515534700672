import { ROUTES } from 'config';
import { Tabs } from 'melp-design/components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

export const RecipientsTabsSwitcher: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <Tabs
      value={pathname}
      onChange={(v) => history.push(v)}
      items={[
        {
          label: t('recognition.recipientsTab'),
          value: ROUTES.recognitions.recipients.list,
        },
        {
          label: t('recognition.leaderboard.title'),
          value: ROUTES.recognitions.leaderboard.list,
        },
      ]}
    />
  );
};
