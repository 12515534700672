import { FC } from 'react';
import { AdminClientLayout } from 'pages/atoms';
import { AdminClientCompanyExpensesList } from 'containers/admin';

const AdminClientCompanyExpensesPage: FC = () => {
  return (
    <AdminClientLayout>
      <AdminClientCompanyExpensesList />
    </AdminClientLayout>
  );
};

export default AdminClientCompanyExpensesPage;
