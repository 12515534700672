import { FC, useRef, useState } from 'react';
import { ButtonBase, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Badge, DropdownMenu, Typography } from 'melp-design/components';
import { SystemColors } from 'melp-design/style';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  parent: {
    height: 70,
    padding: '15px 10px',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    color: SystemColors.grey[55],
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: SystemColors.primary.base,
    },
  },
  active: {
    color: SystemColors.primary.base,
  },
  arrow: {
    color: SystemColors.primary.base,
    marginBottom: 1,
  },
  marker: {
    height: 1,
    width: 'calc(100% - 18px)',
    backgroundColor: SystemColors.primary.base,
    marginBottom: '-1px',
  },
}));

interface ChildRoute {
  id: string;
  label: string;
  path: string;
  badge?: number;
}

interface Props {
  label: string;
  id: string;
  path?: string;
  badge?: number;
  childrenRoutes?: ChildRoute[];
  active?: boolean;
}

const NavMenuItem: FC<Props> = ({
  id,
  label,
  path,
  badge,
  active = false,
  childrenRoutes,
}) => {
  const classes = useStyles();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const withChildrenRoutes = !!childrenRoutes?.length;

  const renderParent = () => {
    const text = (
      <Typography variant="p1" color={active ? 'primary' : 'inherit'}>
        {label}
      </Typography>
    );

    if (withChildrenRoutes) {
      return (
        <ButtonBase
          onClick={() => setOpen(true)}
          className={clsx(classes.parent, (open || active) && classes.active)}
          data-test={id}
        >
          <span ref={anchorRef}>{text}</span>
          <KeyboardArrowDown fontSize="small" color="inherit" />
        </ButtonBase>
      );
    }

    return (
      <NavLink to={path ?? '#'} className={classes.parent}>
        {text}
      </NavLink>
    );
  };

  return (
    <Stack display="inline-flex" alignItems="center">
      <Badge
        badgeContent={badge}
        color="error"
        sx={{ '.MuiBadge-badge': { top: 20 } }}
        max={9}
      >
        {renderParent()}
      </Badge>
      {active && <div className={classes.marker} />}
      {withChildrenRoutes && (
        <DropdownMenu
          anchorEl={anchorRef.current}
          open={open}
          onClose={() => setOpen(false)}
          onItemClick={() => setOpen(false)}
          menuItems={childrenRoutes}
        />
      )}
    </Stack>
  );
};

export default NavMenuItem;
