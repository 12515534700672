import { useQuery } from '@tanstack/react-query';
import { queryKeyFactory } from 'utils/query-client';
import { loaders } from './admin-client-deposits.loaders';
import { converters } from './admin-client-deposits.converters';
import {
  AdminCompanyExpensesFilters,
  AdminClientDepositsFilters,
  AdminDepositEventsFilters,
  AdminDepositHistoryFilters,
  AdminDepositBalancesPerClientFilters,
  AdminDepositBalancesPerCompanyFilters,
} from './admin-client-deposits.types';

export const adminClientDepositsKeys = queryKeyFactory('admin-client-deposits');

export const useAdminClientDeposits = (
  clientId: string,
  filters: Partial<AdminClientDepositsFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list('deposits', clientId, filters),
    queryFn: () => loaders.getDeposits({ clientId, filters }),
    select: (data) => converters.getDeposits(data, filters.sort),
    enabled: !!clientId,
    keepPreviousData: true,
  });
};

export const useAdminCompanyExpenses = (
  companyId: string,
  filters: Partial<AdminCompanyExpensesFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list(
      'deposit-expenses',
      companyId,
      filters,
    ),
    queryFn: () => loaders.getCompanyExpenses({ companyId, filters }),
    select: converters.getCompanyExpenses,
    enabled: !!companyId,
    keepPreviousData: true,
  });
};

export const useAdminDepositEvents = (
  filters: Partial<AdminDepositEventsFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list('deposit-events', filters),
    queryFn: () => loaders.getDepositEvents({ filters }),
    select: converters.getDepositEvents,
    keepPreviousData: true,
  });
};

export const useAdminDepositHistory = (
  clientId: string,
  filters: Partial<AdminDepositHistoryFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list(
      'deposit-history',
      clientId,
      filters,
    ),
    queryFn: () => loaders.getDepositHistory({ clientId, filters }),
    select: converters.getDepositHistory,
    enabled: !!clientId,
    keepPreviousData: true,
  });
};

export const useAdminDepositBalancesPerClient = (
  filters: Partial<AdminDepositBalancesPerClientFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list(
      'deposit-balances',
      'client',
      filters,
    ),
    queryFn: () => loaders.getDepositBalancesPerClient({ filters }),
    select: converters.getDepositBalancesPerClient,
  });
};

export const useAdminDepositBalancesPerCompany = (
  filters: Partial<AdminDepositBalancesPerCompanyFilters> = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list(
      'deposit-balances',
      'company',
      filters,
    ),
    queryFn: () => loaders.getDepositBalancesPerCompany({ filters }),
    select: converters.getDepositBalancesPerCompany,
  });
};

export const useAdminDepositPendingActions = (
  options: { enabled?: boolean } = {},
) => {
  return useQuery({
    queryKey: adminClientDepositsKeys.list('pending-actions'),
    queryFn: () => loaders.getPendingActions(),
    select: converters.getPendingActions,
    enabled: options.enabled,
  });
};
